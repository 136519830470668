import { updateMarketRule, getMarkets, parseRules } from '../../functions';
import type { ParsedRule } from '../../functions';
import type { PrimaryCountryISO } from '@/constants/countries';
import { PRIMARY_MARKETS } from '@/constants/markets';

export const extractSnowflakeInfoFromRules = (
  rules: ParsedRule[]
): [number, number, number, number, number] => {
  const snowflakeScoreName = [
    'value_score',
    'future_performance_score',
    'past_performance_score',
    'health_score',
    'dividends_score',
  ];

  const scores = snowflakeScoreName.map((scoreName) => {
    const filterItem = rules.find((rule) => rule[0] === scoreName) || [];
    const filterScore = (filterItem[2] as number) || 0;
    return filterScore + 1;
  });

  return [scores[0], scores[1], scores[2], scores[3], scores[4]];
};

export const updateRulesWithUserMarket = (
  itemRules: ParsedRule[],
  currentRules: string | undefined,
  defaultUserMarket: PrimaryCountryISO
) => {
  const currentMarkets = getMarkets(parseRules(currentRules || '[]'));

  const isCurrentMarketExist =
    currentMarkets.countries.length > 0 ||
    currentMarkets.exchangeSymbols.length > 0;

  const updatedCountries = isCurrentMarketExist
    ? currentMarkets.countries
    : [defaultUserMarket];

  const defaultUserExchangeSymbols =
    PRIMARY_MARKETS[defaultUserMarket]?.children || [];

  const updatedExchangeSymbols = isCurrentMarketExist
    ? currentMarkets.exchangeSymbols
    : [...defaultUserExchangeSymbols];

  return updateMarketRule(updatedCountries, updatedExchangeSymbols, itemRules);
};
