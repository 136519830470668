import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  cloneElement,
} from 'react';
import type { ReactNode, ReactElement } from 'react';
import {
  CarouselWrapper,
  Embla,
  EmblaButtonNext,
  EmblaButtonPrev,
  EmblaDot,
  EmblaDots,
} from './styled';
import { useEmblaCarousel } from './hooks/useEmblaCarousel';
import type { OptionsType } from 'embla-carousel/embla-carousel-vanilla/options';
import { useRecursiveTimeout } from './hooks/useRecursiveTimeout';
import ChevronRightSolid from '@simplywallst/ui-core/icons/ChevronRightSolid';
import ChevronLeftSolid from '@simplywallst/ui-core/icons/ChevronLeftSolid';
import If from '../If';

const DEFAULT_AUTOPLAY_INTERVAL = 4000;

export interface CarouselProps extends Partial<OptionsType> {
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  showArrows?: boolean;
  showDots?: boolean;
  slidesToScroll?: number;
  totalSlides?: number;
  onScroll?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  children?: ReactNode;
  prevButton?: ReactElement;
  nextButton?: ReactElement;
  activeDot?: ReactElement;
  inactiveDot?: ReactElement;
  showGradient?: boolean;
}

export interface CarouselRef {
  selectedScrollSnap: () => number | undefined;
  scrollNext: () => void;
  scrollPrev: () => void;
  scrollTo: (index: number, jump?: boolean) => void;
  slidesInView: (target?: boolean) => number[] | undefined;
}

export const EmblaCarousel = forwardRef<CarouselRef, CarouselProps>(
  (
    {
      autoPlay: enableAutoPlay,
      autoPlaySpeed,
      showArrows,
      showDots,
      slidesToScroll = 1,
      totalSlides = 0,
      onScroll,
      onClickNext,
      onClickPrev,
      showGradient,
      children,
      ...props
    },
    ref
  ) => {
    const [emblaRef, embla] = useEmblaCarousel({
      slidesToScroll,
      showArrows,
      ...props,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
      if (!embla) return;
      const handler = () => onScroll && onScroll();
      embla.on('select', handler);
      return () => {
        embla.off('select', handler);
      };
    }, [embla, onScroll]);

    useEffect(() => {
      if (!embla) return;
      if (embla.slideNodes().length !== totalSlides) {
        embla.reInit();
      }
    }, [embla, totalSlides]);

    const autoplay = useCallback(() => {
      if (!embla) return;
      if (!enableAutoPlay) return;
      if (embla.canScrollNext()) {
        embla.scrollNext();
      } else {
        embla.scrollTo(0);
      }
    }, [embla, enableAutoPlay]);

    const { play, stop } = useRecursiveTimeout(
      autoplay,
      autoPlaySpeed ?? DEFAULT_AUTOPLAY_INTERVAL
    );

    const scrollNext = useCallback(() => {
      embla?.scrollNext();
      onClickNext?.();
      stop();
    }, [embla, onClickNext, stop]);

    const scrollPrev = useCallback(() => {
      embla?.scrollPrev();
      onClickPrev?.();
      stop();
    }, [embla, onClickPrev, stop]);

    const scrollTo = useCallback(
      (index: number, jump?: boolean) => {
        embla?.scrollTo(index, jump);
        stop();
      },
      [embla, stop]
    );

    useEffect(() => {
      if (!embla) return;
      const onSelect = () => setSelectedIndex(embla.selectedScrollSnap());

      onSelect();
      embla.on('select', onSelect);
    }, [embla, stop]);

    useEffect(() => {
      play();
    }, [play]);

    useImperativeHandle(ref, () => ({
      selectedScrollSnap: () => embla?.selectedScrollSnap(),
      scrollNext: () => embla?.scrollNext(),
      scrollPrev: () => embla?.scrollPrev(),
      scrollTo: (index: number, jump?: boolean) => {
        embla?.scrollTo(index, jump);
      },
      slidesInView: (target = true) => embla?.slidesInView(target),
    }));

    let customPrevButton: ReturnType<typeof cloneElement> | undefined;
    if (typeof props.prevButton !== 'undefined') {
      customPrevButton = cloneElement(props.prevButton, {
        onClick: scrollPrev,
        disabled: !embla?.canScrollPrev(),
      });
    }
    let customNextButton: ReturnType<typeof cloneElement> | undefined;
    if (typeof props.nextButton !== 'undefined') {
      customNextButton = cloneElement(props.nextButton, {
        onClick: scrollNext,
        disabled: !embla?.canScrollNext(),
      });
    }

    const showLeftGradient = embla?.canScrollPrev() && showGradient;
    const showRightGradient = embla?.canScrollNext() && showGradient;

    return (
      <>
        <Embla
          ref={emblaRef}
          showLeftGradient={showLeftGradient}
          showRightGradient={showRightGradient}
        >
          {children}
        </Embla>
        <If
          match={
            showArrows ||
            typeof customPrevButton !== 'undefined' ||
            typeof customNextButton !== 'undefined'
          }
        >
          {() => {
            function getPrevButton() {
              if (typeof customPrevButton !== 'undefined') {
                return customPrevButton;
              } else {
                return (
                  <EmblaButtonPrev
                    onClick={scrollPrev}
                    disabled={!embla?.canScrollPrev()}
                  >
                    <ChevronLeftSolid />
                  </EmblaButtonPrev>
                );
              }
            }
            function getNextButton() {
              if (typeof customNextButton !== 'undefined') {
                return customNextButton;
              } else {
                return (
                  <EmblaButtonNext
                    onClick={scrollNext}
                    disabled={!embla?.canScrollNext()}
                  >
                    <ChevronRightSolid />
                  </EmblaButtonNext>
                );
              }
            }
            return (
              <>
                {getPrevButton()}
                {getNextButton()}
              </>
            );
          }}
        </If>
        {showDots && totalSlides > 1 && (
          <EmblaDots>
            {Array.from({
              length: Math.ceil(totalSlides / slidesToScroll),
            }).map((_, index) => {
              if (index === selectedIndex) {
                let customActiveDot:
                  | ReturnType<typeof cloneElement>
                  | undefined;
                if (typeof props.activeDot !== 'undefined') {
                  customActiveDot = cloneElement(props.activeDot, {
                    onClick: () => scrollTo(index),
                    key: index,
                  });
                }
                if (typeof customActiveDot !== 'undefined') {
                  return customActiveDot;
                }
                return (
                  <EmblaDot
                    key={index}
                    selected
                    onClick={() => scrollTo(index)}
                  />
                );
              } else {
                let customDot: ReturnType<typeof cloneElement> | undefined;
                if (typeof props.inactiveDot !== 'undefined') {
                  customDot = cloneElement(props.inactiveDot, {
                    onClick: () => scrollTo(index),
                    key: index,
                  });
                }
                if (typeof customDot !== 'undefined') {
                  return customDot;
                }
                return (
                  <EmblaDot
                    key={index}
                    selected={false}
                    onClick={() => scrollTo(index)}
                  />
                );
              }
            })}
          </EmblaDots>
        )}
      </>
    );
  }
);

EmblaCarousel.displayName = 'EmblaCarousel';

const Carousel = forwardRef<CarouselRef, CarouselProps>((props, ref) => {
  return (
    <CarouselWrapper>
      <EmblaCarousel ref={ref} {...props} />
    </CarouselWrapper>
  );
});

Carousel.displayName = 'Carousel';

export default Carousel;
