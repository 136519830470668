import styled from 'styled-components';
import { Column } from '@simplywallst/ui-core';

export const Container = styled(Column)`
  padding-top: ${({ theme }) => theme.x2space}rem;
  padding-bottom: ${({ theme }) => theme.x2space}rem;
`;

export const Tag = styled('span')`
  display: inline-block;
  font-size: 1.2rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.whiteRGBA(0.8)};
  background-color: ${({ theme }) => theme.color.whiteRGBA(0.1)};
  border-radius: ${({ theme }) => theme.x1space}rem;
  padding: ${({ theme }) => theme.x1space / 2}rem
    ${({ theme }) => theme.x1space}rem;
  margin-right: ${({ theme }) => theme.x1space / 2}rem;
  margin-bottom: ${({ theme }) => theme.x1space}rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
  max-width: 100%;
  white-space: normal;
  &:hover {
    background-color: ${({ theme }) => theme.color.whiteRGBA(0.2)};
  }
`;
