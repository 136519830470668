import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';
import type { VariablesOf } from '@/utilities/gql';
import { produce } from 'immer';
import { useQueryClient } from '@tanstack/react-query';
import type { GridView } from './utils';
import { useUserScreeners } from './useUserScreeners';
import { useGqlUserScreener } from './useGqlUserScreener';

const MUTATION = gql(
  `
  mutation deleteScreener(
        $id: Int!
      ) {
        deleteScreener(
          id: $id
        )
      }
`
);

export type DeleteScreenerVariables = VariablesOf<typeof MUTATION>;

const useDeleteScreenerBase = makeGqlMutation(MUTATION);

type Options = {
  onDelete?: () => void;
};

export const useGqlDeleteScreener = ({ onDelete }: Options) => {
  const queryClient = useQueryClient();

  return useDeleteScreenerBase({
    onSuccess: (data, response) => {
      const id = String(response.id);
      const setDataFunc = (state: GridView[] = []) =>
        produce(state, (draft) => {
          const index = draft.findIndex((n) => n.id === id);
          if (index !== -1) draft.splice(index, 1);
        });

      queryClient.setQueryData<GridView[]>(
        useUserScreeners.getAllOptions().queryKey,
        setDataFunc
      );

      queryClient.removeQueries({
        queryKey: useGqlUserScreener.getAllOptions().queryKey,
      });
      if (typeof onDelete !== 'undefined') {
        onDelete();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(useUserScreeners.getAllOptions());
    },
  });
};
