import styled from 'styled-components';
import { hexToRgbA } from '@/utilities/colors';

interface Invert {
  invert?: boolean;
}

interface CheckboxWrapperProps {
  disabled?: boolean;
}

interface CheckboxLabelProps {
  displayType: string;
}

export const CheckboxLabel = styled('label')<CheckboxLabelProps>`
  ${({ theme }) => theme.typography.xSmall}
  padding: ${({ theme }) => theme.x1spacePx / 4}px;
  padding-left: ${({ theme }) => theme.x4spacePx}px;
  display: inline-block;
  position: relative;
  display: ${({ displayType }) => displayType};
`;

export const CheckboxWrapper = styled('fieldset')<
  CheckboxWrapperProps & Invert
>`
  position: relative;
  z-index: 0;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  color: ${({ theme, invert }) =>
    invert ? theme.color.darkerGray : theme.color.white};
  label {
    cursor: ${({ disabled }) => (disabled ? `auto` : `pointer`)};
  }
  input {
    opacity: 0;
    position: absolute;
    top: ${({ theme }) => theme.x1spacePx / 2}px;
    left: ${({ theme }) => theme.x1spacePx / 2}px;
    &:checked {
      + span {
        background: ${({ theme }) => theme.color.brand01};
        border-color: ${({ theme }) => theme.color.brand01};
        > svg {
          display: block;
        }
      }
    }
    body.s-focus-kb &:focus-visible {
      + span + span {
        display: block;
        position: absolute;
        top: -3px;
        left: -3px;
        right: -2px;
        bottom: -2px;
        border: dashed 1px ${({ theme }) => theme.color.brand01};
        border-radius: ${({ theme }) => theme.x1spacePx / 2}px;
      }
    }
    &:active:enabled {
      + span {
        background: ${({ theme }) => hexToRgbA(theme.color.brand01, 0.2)};
        > svg {
          display: block;
        }
      }
    }
    &:hover:enabled:not(:checked) {
      + span {
        background: ${({ theme }) => hexToRgbA(theme.color.brand01, 0.1)};
      }
    }
  }
`;

export const CheckboxBase = styled('span')<Invert>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid
    ${({ theme, invert }) =>
      hexToRgbA(invert ? theme.color.darkerGray : theme.color.white, 0.4)};
  width: ${({ theme }) => theme.x3spacePx}px;
  height: ${({ theme }) => theme.x3spacePx}px;
  border-radius: ${({ theme }) => theme.x1spacePx / 2}px;
  transition:
    background 0.2s cubic-bezier(0.23, 1, 0.32, 1),
    border 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.05s;
  > svg {
    display: none;
    position: absolute;
    top: -1px;
    left: -1px;
    > * {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;
