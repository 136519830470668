import { Grid, Text, Box, useTheme } from '@simplywallst/ui-core';

import CheckSolid from '@simplywallst/ui-core/icons/CheckSolid';

type Props = {
  label: string;
  selected: boolean;
  byline?: string;
};

export const Option = ({ label, selected, byline = '' }: Props) => {
  const theme = useTheme();
  return (
    <Grid templateColumns="36px auto" gap="8px" alignItems="start">
      <Grid minHeight="36px" minWidth="36px" justifyContent="end">
        {selected && (
          <CheckSolid height="28" width="28" fill={theme.color.chart01} />
        )}
      </Grid>
      <Box maxWidth={200}>
        <Text typography="xSmall" color="phantom" textAlign="left" isTruncated>
          {label}
        </Text>
        <Text typography="tiny" color="darkGray" textAlign="left" isTruncated>
          {byline}
        </Text>
      </Box>
    </Grid>
  );
};
