import type { ParsedRule } from '../../functions';

const DIVIDEND_POWERHOUSE = {
  name: 'Dividend Powerhouses',
  rules: [
    ['value_score', '>=', 0],
    ['dividends_score', '>=', 4],
    ['future_performance_score', '>=', 0],
    ['health_score', '>=', 4],
    ['past_performance_score', '>=', 0],
    ['market_cap', 'is_not_null'],
    ['current_dividend', 'between', 3, 10],
    ['primary_flag', '=', 'true'],
    ['grid_visible_flag', '=', true],
    ['past_five_year_growth', 'between', 0, 2147483647],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['order_by', 'dividends_score', 'desc'],
    ['grid_visible_flag', '=', true],
  ],
};

const UNDERVALUED_BASED_ON_CASH_FLOWS = {
  name: 'Undervalued based on cash flows',
  rules: [
    ['value_score', '>=', 0],
    ['dividends_score', '>=', 0],
    ['future_performance_score', '>=', 4],
    ['health_score', '>=', 2],
    ['past_performance_score', '>=', 0],
    ['future_performance_score', 'is_not_null', 4],
    ['primary_flag', '=', true],
    ['intrinsic_discount', 'between', 10, 2147483647],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['order_by', 'intrinsic_discount', 'desc'],
    ['grid_visible_flag', '=', true],
  ],
};

const UNDERVALUED_SMALL_CAPS = {
  name: 'Undervalued small caps which insiders are buying',
  rules: [
    ['value_score', '>=', 4],
    ['dividends_score', '>=', 0],
    ['future_performance_score', '>=', 0],
    ['health_score', '>=', 0],
    ['past_performance_score', '>=', 0],
    ['market_cap', 'is_not_null'],
    ['insider_buying', 'between', 0.6, 1],
    ['primary_flag', '=', 'true'],
    ['grid_visible_flag', '=', true],
    ['market_cap', 'between', 100, 1000],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['order_by', 'insider_buying', 'desc'],
    ['grid_visible_flag', '=', true],
  ],
};

const HIGH_GROWTH_TECH_AI = {
  name: 'High growth tech and AI stocks',
  rules: [
    ['value_score', '>=', 0],
    ['dividends_score', '>=', 0],
    ['future_performance_score', '>=', 4],
    ['health_score', '>=', 0],
    ['past_performance_score', '>=', 0],
    ['market_cap', 'is_not_null'],
    [
      'aor',
      [
        ['primary_industry_id', 'in', ['8100000', '4300000', '8010000']],
        [
          'secondary_industry_id',
          'in',
          [
            '8110000',
            '8120000',
            '8130000',
            '9551729',
            '4300001',
            '4300002',
            '4310000',
            '6110000',
            '8030000',
            '8040000',
          ],
        ],
        [
          'tertiary_industry_id',
          'in',
          [
            '8111000',
            '9621091',
            '8131000',
            '8132000',
            '8133000',
            '9612017',
            '9551730',
            '4313000',
            '8043000',
            '4300003',
            '4311000',
            '4312000',
            '4314000',
            '9612013',
            '6111000',
            '8030001',
            '8031000',
            '8032000',
            '8041000',
            '8042000',
          ],
        ],
      ],
    ],
    ['primary_flag', '=', true],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['grid_visible_flag', '=', true],
    ['order_by', 'future_performance_score', 'desc'],
    ['grid_visible_flag', '=', true],
  ],
};

const INSIDER_ONWERSHIP_FORECAST_GROWTH = {
  name: 'Fast growing stocks with high insider ownership',
  rules: [
    ['value_score', '>=', 0],
    ['dividends_score', '>=', 0],
    ['future_performance_score', '>=', 4],
    ['health_score', '>=', 0],
    ['past_performance_score', '>=', 0],
    ['value_score', 'is_not_null', 0],
    ['ownership_proportion_individual_insiders', 'between', 0.1, 0.4],
    ['primary_flag', '=', true],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['order_by', 'future_performance_score', 'desc'],
    ['grid_visible_flag', '=', true],
    [
      'aor',
      [
        ['country_name', 'in', ['au']],
        ['exchange_symbol', 'in', ['asx', 'chia', 'nsx']],
      ],
    ],
  ],
};

const UNDISCOVER_GEMS = {
  name: 'Undiscovered gems with solid fundamentals',
  rules: [
    ['value_score', '>=', 0],
    ['dividends_score', '>=', 0],
    ['future_performance_score', '>=', 0],
    ['health_score', '>=', 4],
    ['past_performance_score', '>=', 3],
    ['value_score', 'is_not_null', 0],
    ['primary_flag', '=', 'true'],
    ['exact_analyst_count', 'between', 0, 3],
    ['market_cap', 'between', 100, 2000],
    ['is_fund', '=', false],
    ['grid_visible_flag', '=', true],
    ['grid_visible_flag', '=', true],
    ['order_by', 'analyst_count', 'asc'],
    ['grid_visible_flag', '=', true],
  ],
};

type ScreenItem = {
  name: string;
  rules: ParsedRule[];
};

export const POPULAR_SCREENS: ScreenItem[] = [
  DIVIDEND_POWERHOUSE,
  UNDERVALUED_BASED_ON_CASH_FLOWS,
  UNDERVALUED_SMALL_CAPS,
  HIGH_GROWTH_TECH_AI,
  INSIDER_ONWERSHIP_FORECAST_GROWTH,
  UNDISCOVER_GEMS,
];
