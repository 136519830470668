import { Box, Grid, Button } from '@simplywallst/ui-core';
import type { UserView } from '@/pages/Screener';
import { ScreenerDropdown } from '@/pages/Screener/components/ScreenerDropdown';

import { Settings } from './Settings';
import { Background } from './styled';

type Props = {
  currentUserViewId: string;
  screenerName: string;
  isPending: boolean;
  onChangeUserView: () => void;
  onSaveScreenerClick: () => void;
  onDelete: () => void;
  onReset: () => void;
  onUpdate: (payload: { name: string; description: string }) => void;
  userViews: UserView[];
};

export const Header = ({
  currentUserViewId,
  screenerName,
  isPending,
  onChangeUserView,
  onSaveScreenerClick,
  onDelete,
  onReset,
  onUpdate,
  userViews,
}: Props) => {
  const currentUserView = userViews.find(
    (userView: UserView) => userView.id === currentUserViewId
  );

  const isResetShown = currentUserViewId === '0';
  const isSettingsShown = currentUserViewId !== '0' && currentUserView;

  return (
    <>
      <Background>
        <Grid
          templateColumns="minmax(100px, max-content) max-content"
          justifyContent="space-between"
          paddingTop="x2"
          paddingTopLg="x1"
          paddingBottom="x2"
          paddingRight="x2"
          paddingRightLg="x0"
          paddingLeft="x0"
        >
          <ScreenerDropdown
            currentUserViewId={currentUserViewId}
            userViews={userViews}
            onChangeUserView={onChangeUserView}
            screenerName={screenerName}
          />
          <Grid
            templateColumns="max-content max-content"
            justifyContent="end"
            columnGap="8px"
          >
            <Box>
              {isSettingsShown && (
                <Settings
                  currentUserViewId={currentUserViewId}
                  userViews={userViews}
                  onDelete={onDelete}
                  onReset={onReset}
                  onUpdate={onUpdate}
                />
              )}
              {isResetShown && (
                <Button styleType="passive" onClick={onReset}>
                  Reset
                </Button>
              )}
            </Box>
            <Box>
              {currentUserView ? (
                <Button
                  disabled={isPending}
                  isLoading={isPending}
                  styleType="louder"
                  onClick={() => {
                    onUpdate({
                      description: currentUserView.description,
                      name: currentUserView.name,
                    });
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={isPending}
                  isLoading={isPending}
                  styleType="louder"
                  onClick={() => {
                    onSaveScreenerClick();
                  }}
                >
                  <span className="inline-block sm:hidden">Save</span>
                  <span className="hidden sm:inline-block">
                    Save & get alerts
                  </span>
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Background>
    </>
  );
};
