import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export const SnowflakeConfigWrapper = styled.div`
  position: relative;
  &:before {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 0;
    background: #3b4754;
    border-radius: 50%;
    width: 100%;
    padding-bottom: 100%;
  }
  width: 100%;
  h4,
  p {
    margin-bottom: 0;
    margin-bottom: 0;
  }
  > span {
    > p {
      svg {
        width: ${({ theme }) => theme.x2spacePx}px;
        height: ${({ theme }) => theme.x2spacePx}px;
        margin-bottom: -3px;
        * {
          fill: white;
        }
      }
    }
  }
  > div {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
`;

export const SnowflakeWrapper = styled('div')`
  text-align: center;
  > div {
    ${media.smDown`
			max-width: 280px;
		`};
    ${media.mdUp`
			max-width: 300px;
		`};
    margin: 0 auto;
    > div {
      padding-bottom: 0;
    }
  }
  ${media.smDown`
		padding-bottom: ${({ theme }) => theme.x3spacePx}px;
	`}
  ${media.mdUp`
		top: ${({ theme }) => theme.x4spacePx}px;
		right: 0;
		z-index: 1;
	`}
`;
