import { useTracking } from 'react-tracking';
import { Modal } from '@simplywallst/ui-core';
import { toLower } from 'lodash';
import SelectMarket from '../../../SelectMarket';

interface SelectedMarkets {
  countries: string[];
  exchangeSymbols: string[];
}

interface FilterByMarketModalProps {
  selectedMarkets: SelectedMarkets;
  onDeselect: (type: 'market') => void;
  onConfirm: () => void;
  onChange: (
    updatedCountries: string[],
    updatedExchangeSymbols: string[]
  ) => void;
  onClose: () => void;
}

const defaultProps = {};

const FilterByMarketModal = ({
  onClose,
  onDeselect,
  onConfirm,
  selectedMarkets,
  onChange,
}: FilterByMarketModalProps) => {
  const { Track } = useTracking({
    type: 'track',
    subject: 'FilterByMarketModal',
  });
  return (
    <Track>
      <Modal
        title="Filter by Markets"
        onPrimaryClick={onConfirm}
        secondaryLabel="Clear selections"
        onSecondaryClick={() => onDeselect('market')}
        primaryLabel="Done"
        fullscreen={true}
        onClose={onClose}
      >
        <SelectMarket
          selectedMarkets={[
            ...selectedMarkets.countries,
            ...selectedMarkets.exchangeSymbols,
            // legacy response sometimes has upper cased markets and exchanges
          ].map(toLower)}
          onChange={onChange}
        />
      </Modal>
    </Track>
  );
};

FilterByMarketModal.defaultProps = defaultProps;

export default FilterByMarketModal;
