import { useState, useEffect, useRef } from 'react';
import type { MouseEvent } from 'react';
import type { QueryKeyword } from '@/constants/industries';
import { Checkbox } from '@components/Checkbox';
import { Toggle } from './styled';

interface Props {
  children?: string[] | readonly string[];
  categoryFilter: string[];
  label: string;
  id: string;
  depth: number;
  selected: string[];
  listing: Record<string, QueryKeyword>[];
  onToggle: (id: string, depth: number) => void;
}

const CategoryNode = ({
  categoryFilter,
  listing,
  depth = 0,
  selected,
  children,
  label,
  id,
  onToggle,
}: Props) => {
  const [hiddenChildCount, setHiddenChildCount] = useState(0);
  const categoryFilterRef = useRef<string[]>([]);

  useEffect(() => {
    if (
      children &&
      categoryFilterRef.current.length !== categoryFilter.length
    ) {
      const hiddenChildren =
        children.length -
        children.filter((n) => categoryFilter.includes(n)).length;
      categoryFilterRef.current = categoryFilter;
      setHiddenChildCount(hiddenChildren);
    }
  }, [categoryFilter, children, listing.length]);

  return (
    <li>
      <Toggle
        depth={depth}
        onClick={(e: MouseEvent) => {
          const { nodeName } = e.target as HTMLDivElement;
          /** only fire event when clicking on container */
          if (nodeName.toLowerCase() === 'div') {
            onToggle(id, depth);
          }
        }}
      >
        <Checkbox
          display="block"
          name={label}
          invert
          trackingProps={{
            depth,
            id,
            label,
            modifier: 'item',
          }}
          label={label}
          value={selected.indexOf(id) !== -1}
          onChange={() => {
            onToggle(id, depth);
          }}
        />
      </Toggle>
      {children && children.length > 0 && (
        <ul>
          {children
            .filter((n) => hiddenChildCount === 0 || categoryFilter.includes(n))
            .map((n) => {
              const category = listing[depth + 1][n];
              return (
                <CategoryNode
                  key={`category=${depth + 1}-node-${n}`}
                  id={n}
                  onToggle={onToggle}
                  label={category.label}
                  categoryFilter={categoryFilter}
                  selected={selected}
                  depth={depth + 1}
                  listing={listing}
                >
                  {category.children}
                </CategoryNode>
              );
            })}
        </ul>
      )}
    </li>
  );
};

export default CategoryNode;
