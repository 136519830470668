import { Component } from 'react';
import { SnowflakeWrapper } from './styled';
import loadable from '@loadable/component';
const Snowflake = loadable(() => import('@/components/DeprecatedSnowflake'));

interface Props {
  score: number[];
  onChange: (points: number[]) => void;
}
interface State {
  points: number[];
  isUpdated: boolean;
}

const addOne = (point) => point + 1;

let intervalState = 0;
class SnowflakeConfig extends Component<Props, State> {
  static defaultProps = {
    score: [0, 0, 0, 0, 0],
    onChange: () => null,
  };
  private scoreTypes: string[];
  constructor(props) {
    super(props);
    this.state = {
      //Score starts from 0, but points calculation start from 1
      points: props.score ? props.score.map((x) => x + 1) : [1, 1, 1, 1, 1],
      isUpdated: false,
    };
    this.scoreTypes = ['value', 'future', 'past', 'health', 'dividend'];
  }
  /* Works with React 16.5 */
  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): null | State {
    // 🤮
    if (prevState.isUpdated) return null;
    const scorePlusOne = nextProps.score.map(addOne);
    const isEq = scorePlusOne.toString() === prevState.points.toString();
    return isEq ? prevState : { ...prevState, points: scorePlusOne };
  }

  handleChange = (points: number[]) => {
    this.setState({ points: points });
    //
    const { onChange } = this.props;
    if (onChange) {
      try {
        this.setState(
          {
            isUpdated: true,
          },
          () => {
            onChange(points.map((n) => n - 1));
            clearInterval(intervalState);
            intervalState = window.setInterval(() => {
              // We will set `isUpdated` to false after 1 second to allow new props to come in
              this.setState({ isUpdated: false });
              clearInterval(intervalState);
            }, 1000);
          }
        );
      } catch (err) {
        console.error('Failed to call onChange..', err);
      }
    }
  };

  render() {
    const { points } = this.state;
    const { handleChange } = this;

    return (
      <SnowflakeWrapper>
        <Snowflake
          points={points}
          editable={true}
          handleChange={handleChange}
        />
      </SnowflakeWrapper>
    );
  }
}

export default SnowflakeConfig;
