import { useState, useEffect, useCallback } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import { xor, debounce, find } from 'lodash';
import { parseRules, getFilters } from '../../functions';
import { filters as filterEnum } from '../../constants';
import { MoreFiltersConfig } from '../MoreFiltersConfig';

interface Props {
  onChange: (
    toggledMoreFilters: string[],
    toggledRule: Record<string, string>
  ) => void;
  rules: string;
}

const SelectFields = ({ onChange, rules }: Props) => {
  const [toggledMoreFilters, setToggledMoreFilters] = useState<string[]>([]);
  const [toggledRules, setToggledRules] = useState<Record<string, string>>({});

  useEffect(() => {
    const parsedRules = parseRules(rules);
    const filters = getFilters(parsedRules, false);
    const toggledRule = parsedRules
      // remove the other stuff (market/industry/snowflake)
      .filter((current) => {
        let rule = current;
        if (rule[0] === 'aor') {
          rule = rule[1][0];
        }
        return filters.includes(rule[0]) && rule[1] !== 'is_not_null';
      })
      // create a rule object that our component can understand
      .reduce<Record<string, string>>((acc, current) => {
        let rule = current;
        if (rule[0] === 'aor') {
          rule = rule[1][0]; // Get the first nested rule (assume this is the important one....FML)
        }
        return {
          ...acc,
          [rule[0].toString()]: JSON.stringify(rule),
        };
      }, {});
    // if ['is_fund'] doesn't exist it means that 'Show ETF & Fund' is toggled
    const foundIsFund = find(
      parsedRules,
      ([ruleName]) => ruleName === filterEnum.isFund
    );
    if (!foundIsFund) {
      toggledRule['is_fund'] = '["is_fund", "=", true]';
    }

    setToggledMoreFilters(filters);
    setToggledRules(toggledRule);
  }, [rules]);

  const debounceOnChange = debounce((moreFilters, rules) => {
    onChange(moreFilters, rules);
  }, 300);

  const handleToggle = useCallback<
    ComponentPropsWithoutRef<typeof MoreFiltersConfig>['onToggle']
  >(
    (toggledId, rule) => {
      /** Checkboxes */

      const updatedToggledMoreFilters = xor(
        toggledMoreFilters,
        handleSpecialCase(toggledId, toggledMoreFilters)
      );
      setToggledMoreFilters(updatedToggledMoreFilters);
      const updatedToggledRules = {
        ...toggledRules,
        ...(rule ? { [toggledId]: rule } : {}),
      };
      setToggledRules(updatedToggledRules);
      onChange(updatedToggledMoreFilters, updatedToggledRules);
    },
    [onChange, toggledMoreFilters, toggledRules]
  );

  const handleChange = useCallback<
    ComponentPropsWithoutRef<typeof MoreFiltersConfig>['onChange']
  >(
    (id, rule) => {
      /** Sliders */
      const updatedToggledRules = {
        ...toggledRules,
        [id]: rule,
      };
      setToggledRules(updatedToggledRules);
      debounceOnChange(toggledMoreFilters, updatedToggledRules);
    },
    [debounceOnChange, toggledMoreFilters, toggledRules]
  );
  return (
    <MoreFiltersConfig
      toggledFilters={toggledMoreFilters}
      rules={toggledRules}
      onToggle={handleToggle}
      onChange={handleChange}
    />
  );
};

function handleSpecialCase(toggledId: string, toggledMoreFilters: string[]) {
  if (
    toggledId === filterEnum.eps &&
    toggledMoreFilters.includes('is_eps_less_than_zero')
  ) {
    return [toggledId, 'is_eps_less_than_zero'];
  } else if (
    toggledId === 'is_eps_less_than_zero' &&
    toggledMoreFilters.includes(filterEnum.eps)
  ) {
    return [toggledId, filterEnum.eps];
  } else {
    return [toggledId];
  }
}

export default SelectFields;
