import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { StyledEmbla } from './styled';
import { Grid, Box, Text } from '@simplywallst/ui-core';
import Carousel from '@/components/Carousel';
import { Snowflake, DotMarkers } from '@simplywallst/ui-charts/Snowflake';
import {
  extractSnowflakeInfoFromRules,
  updateRulesWithUserMarket,
} from './utils';
import { useIsSmallScreen, useIsMediumScreen } from '@/hooks/useMedia';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';
import { useTracking } from 'react-tracking';
import { POPULAR_SCREENS } from './constants';
import useUser from '@/hooks/useUser';

const GAP = 24;
const ITEM_PER_VIEW_SMALL = 1;
const ITEM_PER_VIEW_MEDIUM = 2;
const ITEM_PER_VIEW_LARGE = 3;

interface Props {
  setCurrentRules: Dispatch<SetStateAction<string | undefined>>;
  currentRules: string | undefined;
}

const PopularScreensSlider = ({ setCurrentRules, currentRules }: Props) => {
  const { isMobile, isTablet } = useUserAgent();
  const { data: user } = useUser();
  const defaultUserMarket = user.defaultMarketISOWithUsFallback;

  const { trackEvent } = useTracking({
    type: 'track',
  });

  const isSmallScreen = useIsSmallScreen({ defaultMatches: isMobile });
  const isMediumScreen = useIsMediumScreen({ defaultMatches: isTablet });

  const sliderItemsPerView = useMemo(() => {
    if (isSmallScreen) {
      return ITEM_PER_VIEW_SMALL;
    }
    if (isMediumScreen) {
      return ITEM_PER_VIEW_MEDIUM;
    }
    return ITEM_PER_VIEW_LARGE;
  }, [isMediumScreen, isSmallScreen]);

  const isShownControls = POPULAR_SCREENS.length > sliderItemsPerView;

  const sliderItemsPerViewOnScreen = isShownControls
    ? sliderItemsPerView + 0.5
    : sliderItemsPerView;

  const isShownArrows = !isSmallScreen && isShownControls;

  return (
    <StyledEmbla>
      <Carousel
        align="start"
        totalSlides={POPULAR_SCREENS.length}
        inViewThreshold={1}
        containScroll="trimSnaps"
        showArrows={isShownArrows}
        showGradient
      >
        <Grid
          autoFlow="column"
          autoColumns={`calc((100% - ${
            (sliderItemsPerView - 1) * GAP
          }px) / ${sliderItemsPerViewOnScreen})`}
          gap={`${GAP}px`}
        >
          {POPULAR_SCREENS.map((screenItem, index) => (
            <Box
              paddingX="x1_5"
              paddingY="x1"
              cursor="pointer"
              backgroundColor="phantom"
              boxShadow="subtleBorder"
              borderRadius="x1_5"
              key={index}
              onClick={() => {
                const itemRules = screenItem.rules;
                const rulesWithUserMarket = updateRulesWithUserMarket(
                  itemRules,
                  currentRules,
                  defaultUserMarket
                );
                setCurrentRules(JSON.stringify(rulesWithUserMarket));
                trackEvent({
                  action: 'click',
                  subject: 'slider',
                  modifier: 'prefilled_screen',
                  name: screenItem.name,
                });
              }}
            >
              <Grid templateColumns="48px 1fr" alignItems="center" gap="16px">
                <Snowflake
                  width={48}
                  points={extractSnowflakeInfoFromRules(screenItem.rules)}
                >
                  <DotMarkers />
                </Snowflake>
                <Text typography="xSmall" color="white">
                  {screenItem.name}
                </Text>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Carousel>
    </StyledEmbla>
  );
};

export default PopularScreensSlider;
