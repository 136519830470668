import { useState } from 'react';
import loadable from '@loadable/component';

import { Grid, Text, Button } from '@simplywallst/ui-core';
import BinSolid from '@simplywallst/ui-core/icons/BinSolid';
import CogOutline from '@simplywallst/ui-core/icons/CogOutline';
import EditSolid from '@simplywallst/ui-core/icons/EditSolid';
import RefreshOutline from '@simplywallst/ui-core/icons/RefreshOutline';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';

import { useIsMobileScreen } from '@/hooks/useMedia';
import { Menu, Item } from '@/components/Menu';
import type { UserView } from '@/pages/Screener';

const EditAndSaveScreenerModal = loadable(
  () => import('../EditAndSaveScreenerModal'),
  { resolveComponent: (c) => c.EditAndSaveScreenerModal }
);
const DeleteScreenerModal = loadable(() => import('../DeleteScreenerModal'));

type Props = {
  currentUserViewId: string;
  onDelete: () => void;
  onReset: () => void;
  onUpdate: (payload: { name: string; description: string }) => void;
  userViews: UserView[];
};

export const Settings = ({
  currentUserViewId,
  onDelete,
  onReset,
  onUpdate,
  userViews,
}: Props) => {
  const { isMobile } = useUserAgent();
  const isMobileScreen = useIsMobileScreen({ defaultMatches: isMobile });
  const [modal, setModal] = useState<string | null>(null);

  const handleSelect = (value: string) => {
    switch (value) {
      case 'rename-screener':
        setModal(value);
      case 'delete-screener':
        setModal(value);
      case 'reset-screener':
        onReset();
    }
  };

  const currentUserView = userViews.find(
    (userView: UserView) => userView.id === currentUserViewId
  );

  return (
    <>
      <Menu
        label={
          <Button
            styleType="gentle"
            alignIcon={isMobileScreen ? undefined : 'left'}
          >
            <CogOutline />
            {isMobileScreen ? '' : ' Settings'}
          </Button>
        }
        placement="bottom end"
        onAction={(key) => handleSelect(key as string)}
      >
        <Item textValue="Rename screener" key="rename-screener">
          <Grid templateColumns="24px auto" alignItems="center" gap="8px">
            <EditSolid width="24px" opacity={0.5} />
            <Text typography="xSmall" color="spaceGray">
              Rename screener
            </Text>
          </Grid>
        </Item>
        <Item textValue="Delete screener" key="delete-screener">
          <Grid templateColumns="24px auto" alignItems="center" gap="8px">
            <BinSolid width="24px" opacity={0.5} />
            <Text typography="xSmall" color="spaceGray">
              Delete screener
            </Text>
          </Grid>
        </Item>
        <Item textValue="Reset screener" key="reset-screener">
          <Grid templateColumns="24px auto" alignItems="center" gap="8px">
            <RefreshOutline width="24px" opacity={0.5} />
            <Text typography="xSmall" color="spaceGray">
              Reset screener
            </Text>
          </Grid>
        </Item>
      </Menu>
      {modal === 'rename-screener' && currentUserView && (
        <EditAndSaveScreenerModal
          title={currentUserView.name}
          onClose={() => setModal(null)}
          onConfirm={(name, description) => {
            setModal(null);
            onUpdate({ description, name });
          }}
          oldName={currentUserView.name}
          oldDescription={currentUserView.description}
          confirmLabel="Confirm"
        />
      )}
      {modal === 'delete-screener' && currentUserView && (
        <DeleteScreenerModal
          name={currentUserView.name}
          onClose={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            onDelete();
          }}
        />
      )}
    </>
  );
};
