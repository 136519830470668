import { del } from "../methods";
function deleteGridView(id, headers = {}) {
  return del({
    url: `api/grid/view/${id}`,
    headers
    // requiresAuthentication: true,
  });
}
export {
  deleteGridView as default
};
