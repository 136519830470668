import { useTheme, Button, Text, Heading, Grid } from '@simplywallst/ui-core';
import ChevronDownSmallSolid from '@simplywallst/ui-core/icons/ChevronDownSmallSolid';
import ScreenerOutline from '@simplywallst/ui-core/icons/ScreenerOutline';
import { Item, Menu, Section } from '@/components/Menu';
import type { UserView } from '@/pages/Screener';

import { Option } from './Option';

type Props = {
  currentUserViewId: string;
  onChangeUserView: (id?: string, slug?: string) => void;
  userViews: UserView[];
  screenerName: string;
};

export const ScreenerDropdown = ({
  currentUserViewId,
  onChangeUserView,
  userViews,
  screenerName,
}: Props) => {
  const theme = useTheme();
  const currentUserView = userViews.find(
    (userView: UserView) => userView.id === currentUserViewId
  );

  const items = [
    {
      label: 'Other Options',
      value: 'other-options',
      children: [
        {
          label: 'Create new screener',
          value: 'create-new-screener',
          byline: '',
        },
      ],
    },
  ];

  const name = currentUserView?.name || screenerName || 'Stock Screener';

  if (userViews.length !== 0) {
    items.unshift({
      label: 'User Ideas',
      value: 'user-ideas',
      children: userViews.map((idea: any) => ({
        label: idea.name,
        value: idea.id,
        byline: idea?.description ? idea?.description : 'No description',
      })),
    });
  }

  return (
    <div className="translate-x-0 lg:-translate-x-3">
      <Heading as="h1">
        <Menu
          label={
            <Button styleType="passive" alignIcon="right" display="block">
              <Text
                as="span"
                typography="megaStrong"
                color="textSolid"
                lineClamp={1}
              >
                {name}
              </Text>
              <ChevronDownSmallSolid fill={theme.color.textSubtle} />
            </Button>
          }
          items={items}
          placement="bottom start"
          onAction={(value) => {
            if (value === 'create-new-screener') {
              onChangeUserView();
            }
            const userView = userViews.find(
              (item: UserView) => item.id === value
            );
            if (userView) {
              onChangeUserView(userView?.id, userView?.slug);
            }
          }}
          selectionMode="single"
          selectedKeys={[currentUserViewId]}
        >
          {(item) => (
            <Section items={item.children} key={item.value}>
              {(item) => (
                <Item textValue={item.label} key={item.value}>
                  {item.value === 'create-new-screener' ? (
                    <Grid
                      templateColumns="36px max-content"
                      gap="8px"
                      alignItems="start"
                    >
                      <Grid
                        minHeight="36px"
                        minWidth="36px"
                        alignItems="center"
                        justifyContent="end"
                      >
                        <ScreenerOutline
                          height="28"
                          width="28"
                          fill={theme.color.chart01}
                        />
                      </Grid>
                      <Grid
                        alignItems="start"
                        paddingTop="x1"
                        paddingBottom="x1"
                        paddingRight="x1"
                      >
                        <Text typography="xSmall" color="spaceGray" isTruncated>
                          Create new screener
                        </Text>
                      </Grid>
                    </Grid>
                  ) : (
                    <Option
                      label={item.label}
                      selected={currentUserViewId === item.value}
                      byline={item.byline}
                    />
                  )}
                </Item>
              )}
            </Section>
          )}
        </Menu>
      </Heading>
    </div>
  );
};
