import styled, { css } from 'styled-components';

export const CarouselWrapper = styled('div')`
  overflow: hidden;
  /**
  * We don't apply position: relative; here so that child elements
  * with position: absolute; can break overflow: hidden;
  *
  * Instead look to applying position: relaive; to a parent of
  * the Carousel component
  */
`;

interface EmblaProps {
  showLeftGradient?: boolean;
  showRightGradient?: boolean;
}

export const Embla = styled('div')<EmblaProps>`
  width: 100%;
  height: 100%;
  ${({ showLeftGradient }) =>
    showLeftGradient &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin-left: -4px;
        width: ${({ theme }) => theme.x8spacePx}px;
        background: linear-gradient(
          90deg,
          ${({ theme }) => theme.color.noir} 15.38%,
          ${({ theme }) => theme.color.noirRGBA(0)} 100%
        );
      }
    `};
  ${({ showRightGradient }) =>
    showRightGradient &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin-left: 4px;
        width: ${({ theme }) => theme.x8spacePx}px;
        transform: translateX(-100%);
        background: linear-gradient(
          270deg,
          ${({ theme }) => theme.color.noir} 15.38%,
          ${({ theme }) => theme.color.noirRGBA(0)} 100%
        );
      }
    `};

  .is-draggable {
    cursor: move;
    cursor: grab;
  }

  .is-dragging {
    cursor: grabbing;
  }
`;

export const EmblaContainer = styled('div')`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export interface EmblaSlideProps {
  itemsToShow?: number;
}

export const EmblaSlide = styled('div')<EmblaSlideProps>`
  position: relative;
  display: flex;
  justify-content: center;
  //Allocate width based on number of slides to show
  ${({ itemsToShow = 1 }) => css`
    flex: 0 0 ${Math.round(100 / Math.max(1, itemsToShow))}%;
  `}
`;

export const EmblaButton = styled('button')`
  outline: 0;
  cursor: pointer;
  background-color: transparent !important;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: ${({ theme }) => theme.x4spacePx}px;
  height: ${({ theme }) => theme.x4spacePx}px;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const EmblaSvg = styled('svg')`
  width: 100%;
  height: 100%;
`;

export const EmblaButtonPrev = styled(EmblaButton)`
  left: ${({ theme }) => theme.x4spacePx - 4}px;
`;
export const EmblaButtonNext = styled(EmblaButton)`
  right: ${({ theme }) => theme.x4spacePx - 4}px;
`;

export const EmblaDots = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  list-style: none;
  justify-content: center;
`;

export interface EmblaDotProps {
  selected?: boolean;
}

export const EmblaDot = styled('div')<EmblaDotProps>`
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: ${({ theme }) => theme.x2spacePx}px;
  height: ${({ theme }) => theme.x2spacePx}px;
  margin-right: ${({ theme }) => theme.x1spacePx}px;
  margin-left: ${({ theme }) => theme.x1spacePx}px;
  display: flex;
  align-items: center;

  &:after {
    background-color: ${({ theme }) => theme.color.paper};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.color.lightGray};
    content: '';
  }

  ${({ selected }) =>
    selected &&
    css`
      &:after {
        border: 2px solid ${({ theme }) => theme.color.brand01};
        opacity: 1;
      }
    `}
`;

export const StyledEmbla = styled('div')`
  position: relative;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.x2}px;

  ${EmblaButton} {
    transition: all 0.3s ease-in-out;
    background: ${({ theme }) => theme.color.phantom} !important;
    width: ${({ theme }) => theme.x5spacePx}px;
    height: ${({ theme }) => theme.x5spacePx}px;
    border-radius: ${({ theme }) => theme.x5spacePx}px;
    box-shadow: 0px 5px 5px ${({ theme }) => theme.color.blackRGBA(0.4)};

    > svg > * {
      fill: ${({ theme }) => theme.color.white};
    }

    &:disabled {
      opacity: 0;
    }
  }

  ${EmblaButtonNext} {
    right: ${({ theme }) => theme.x1spacePx}px;

    &:disabled {
      ${({ theme }) => css`
        right: -${theme.x5spacePx}px;
      `}
    }
  }

  ${EmblaButtonPrev} {
    left: ${({ theme }) => theme.x1spacePx}px;

    &:disabled {
      ${({ theme }) => css`
        left: -${theme.x5spacePx}px;
      `}
    }
  }
`;
