import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export const FixedContainer = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.x3spacePx}px;
  right: ${({ theme }) => theme.x3spacePx}px;
  z-index: 1;
  min-height: 56px;
  padding: ${({ theme }) => theme.x2spacePx}px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.darkerGrayRGBA(0.2)};
`;

export const ScrollableContainer = styled.div`
  position: relative;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: -${({ theme }) => theme.x4spacePx}px -${({ theme }) =>
      theme.x3spacePx}px;
  padding: ${({ theme }) => theme.x2spacePx}px
    ${({ theme }) => theme.x3spacePx}px;
  padding-top: ${({ theme }) => theme.x10spacePx}px;
  ${media.mdDown`
    height: calc(100vh - 162px);
    -webkit-overflow-scrolling: touch;
  `};
`;

export const FiltersContainer = styled('div')`
  background-color: ${({ theme }) => theme.color.noir};
  padding-top: ${({ theme }) => theme.x3space}rem;
  padding-bottom: ${({ theme }) => theme.x3space}rem;
`;

export const DetailsLinks = styled('p')`
  text-align: left;
  margin-bottom: 0;
`;
