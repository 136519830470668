import {
  useInfiniteCompanyScreenersQuery,
  useInfiniteUserPredefinedScreenersQuery,
} from '@/hooks/useInfiniteCompanyScreenersQuery';
import { parseRules } from '../functions';
import { checkUsingPredefinedUserQuery } from './utils';

type ScreenerQueryParams = {
  gridViewId?: number;
  rules?: string;
  pageSize: number;
  returnRecentCompaniesOnly?: boolean;
  isRulesDirty: boolean;
};

export const useInfiniteScreenersQuery = ({
  gridViewId,
  rules,
  pageSize,
  returnRecentCompaniesOnly,
  isRulesDirty,
}: ScreenerQueryParams) => {
  const isUsingPredefinedUserQuery = checkUsingPredefinedUserQuery(gridViewId);

  const sharedQueryParams = {
    limit: pageSize,
    initPage: 0,
    returnRecentCompaniesOnly,
  };

  const normalizedRules = rules || '';

  const newScreenerQuery = useInfiniteCompanyScreenersQuery({
    enabled: !isUsingPredefinedUserQuery,
    rules: parseRules(normalizedRules),
    ...sharedQueryParams,
  });

  const userPredefinedScreenerQuery = useInfiniteUserPredefinedScreenersQuery({
    enabled: isUsingPredefinedUserQuery,
    gridViewId,
    adjustedFilterJson: isRulesDirty ? normalizedRules : '',
    ...sharedQueryParams,
  });

  if (isUsingPredefinedUserQuery) {
    return userPredefinedScreenerQuery;
  }

  return newScreenerQuery;
};
