import { Component } from 'react';
import FilterByMarketModal from './components/FilterByMarketModal';
import FilterByIndustryModal from './components/FilterByIndustryModal';
import AdvancedFilterModal from './components/AdvancedFilterModal';
import { FilterTypes } from '../../constants';
import type { Industry } from '@/hooks/useIndustryTree';

import {
  getMarkets,
  getIndustries,
  updateIndustryRule,
  updateMarketRule,
  parseRules,
} from '../../functions';
import { getUpdatedFieldsRules } from './util';
export interface Props {
  type:
    | FilterTypes.additional
    | FilterTypes.industry
    | FilterTypes.market
    | string
    | null;
  rules: string;
  industryList: Record<string, Industry>[];
  onChange: (newRules: string) => void;
  onClose: () => void;
  onConfirm: (updateRuleJson?: string) => void;
}
interface State {
  updatedRuleJson: string;
}

class ModalFilterEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { updatedRuleJson: '' };
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props.rules !== nextProps.rules;
  }

  handleMarketChange = (
    updatedCountries: string[],
    updatedExchangeSymbols: string[]
  ) => {
    const { rules } = this.props;
    const parsedRules = parseRules(rules);
    const newRules = updateMarketRule(
      updatedCountries,
      updatedExchangeSymbols,
      parsedRules
    );
    this.setState({
      updatedRuleJson: JSON.stringify(newRules),
    });
  };

  handleIndustryChange = (
    updatedPrimaryIndustries: string[],
    updatedSecondaryIndustries: string[],
    updatedTertiaryIndustries: string[]
  ) => {
    const { rules } = this.props;
    const parsedRules = parseRules(rules);
    const newRules = updateIndustryRule(
      updatedPrimaryIndustries,
      updatedSecondaryIndustries,
      updatedTertiaryIndustries,
      parsedRules
    );
    this.setState({
      updatedRuleJson: JSON.stringify(newRules),
    });
  };

  handleFieldsChange = (
    toggledMoreFilters: string[],
    toggledRule: Record<string, string>
  ) => {
    const { rules } = this.props;

    console.log(toggledMoreFilters, toggledRule);
    const newRule = getUpdatedFieldsRules(
      rules,
      toggledMoreFilters,
      toggledRule
    );
    this.setState({
      updatedRuleJson: JSON.stringify(newRule),
    });
  };

  handleModalConfirm = () => {
    const { onConfirm } = this.props;
    const { updatedRuleJson } = this.state;
    onConfirm(updatedRuleJson);
  };

  handleDeselectAll = (type: 'market' | 'industry' | 'filter') => {
    const { rules, onConfirm } = this.props;
    const parsedRules = parseRules(rules);
    switch (type) {
      case 'market':
        onConfirm(JSON.stringify(updateMarketRule([], [], parsedRules)));
        return;
      case 'industry':
        onConfirm(JSON.stringify(updateIndustryRule([], [], [], parsedRules)));
        return;
      case 'filter':
        const newRules = getUpdatedFieldsRules(rules, [], {});
        newRules.push(['primary_flag', '=', true]);
        onConfirm(JSON.stringify(newRules));
        return;
    }
  };

  render() {
    const {
      handleModalConfirm,
      handleFieldsChange,
      handleIndustryChange,
      handleMarketChange,
      handleDeselectAll,
    } = this;

    const { rules, type, industryList, onClose } = this.props;
    const parsedRules = parseRules(rules);
    const selectedMarkets = getMarkets(parsedRules);
    const selectedIndustries = getIndustries(parsedRules);

    switch (type) {
      case FilterTypes.additional:
        return (
          <AdvancedFilterModal
            onDeselect={handleDeselectAll}
            onConfirm={handleModalConfirm}
            onChange={handleFieldsChange}
            onClose={onClose}
            rules={rules}
          />
        );
      case FilterTypes.industry:
        return (
          <FilterByIndustryModal
            onDeselect={handleDeselectAll}
            onConfirm={handleModalConfirm}
            onChange={handleIndustryChange}
            selectedIndustries={selectedIndustries}
            industryList={industryList}
            onClose={onClose}
          />
        );
      case FilterTypes.market:
        return (
          <FilterByMarketModal
            onDeselect={handleDeselectAll}
            onConfirm={handleModalConfirm}
            onChange={handleMarketChange}
            selectedMarkets={selectedMarkets}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  }
}

export default ModalFilterEditor;
