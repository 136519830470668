import styled from 'styled-components';
import { Row, media } from '@simplywallst/ui-core';

export const Wrapper = styled(Row)`
  ${media.mdDown`
    padding: 0 ${({ theme }) => theme.x2spacePx}px;
  `}
`;

export const Background = styled('div')`
  ${media.mdDown`
    background: ${({ theme }) => theme.color.noir};
  `}
`;

export const HideLgDown = styled('span')`
  ${media.lgDown`
    display: none;
  `};
`;
