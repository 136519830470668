import { Box, Grid, Text, useTheme } from '@simplywallst/ui-core';
import LockSolid from '@simplywallst/ui-core/icons/LockSolid';
import { IconWrapper } from './styled';

interface Props {
  reason?: string | null;
}

export const ErrorLimit = ({ reason }: Props) => {
  const theme = useTheme();
  return (
    <Box marginY="x8">
      <Grid justifyContent="center">
        <IconWrapper>
          <LockSolid fill={theme.color.chart05} />
        </IconWrapper>
        {reason && (
          <Text textAlign="center" typography="xSmall" color="textSolid">
            {reason}
          </Text>
        )}
      </Grid>
    </Box>
  );
};
