import { escape } from 'lodash';
import { useCallback } from 'react';

const pushStateWhenDifferent = (targetUrl: string) => {
  if (window.location.href === targetUrl) return;
  window.history.pushState({}, '', targetUrl);
};

/**
 *
 * Utility hook to help manage query strings in the URL. Gets, sets and removes query parameter values from the URL.
 */
export function useQueryStringParam() {
  const isServerSide =
    RUNTIME_ENV === 'server' && typeof window === 'undefined';

  const setItem = useCallback(
    (key: string, value: string) => {
      if (isServerSide) {
        return;
      }

      try {
        const { hash, origin, pathname, search } = window.location;
        const url = new URL(pathname, origin);
        const params = new URLSearchParams(search);

        params.set(key, value);
        const updatedParams = `?${params.toString()}`;
        pushStateWhenDifferent(`${url}${updatedParams}${hash}`);
        return;
      } catch (error) {
        console.warn(error);
        console.warn(`Unable to set ${key} in url`);
        return;
      }
    },
    [isServerSide]
  );

  const getItem = useCallback(
    (key: string) => {
      if (isServerSide) {
        return null;
      }

      const { search } = window.location;
      const params = new URLSearchParams(search);

      try {
        const value = params.get(key);
        if (value === null) return null;
        return escape(value);
      } catch (error) {
        console.warn(error);
        console.warn(`Unable to get ${key} in url`);
        return null;
      }
    },
    [isServerSide]
  );

  const removeItem = useCallback(
    (key: string) => {
      if (isServerSide) {
        return;
      }

      const { hash, origin, pathname, search } = window.location;
      if (!pathname || !origin) {
        return;
      }

      const url = new URL(pathname, origin);
      const params = new URLSearchParams(search);

      try {
        params.delete(key);
        const updatedParams =
          params.toString() !== '' ? `?${params.toString()}` : '';
        pushStateWhenDifferent(`${url}${updatedParams}${hash}`);
        return;
      } catch (error) {
        console.warn(error);
        console.warn(`Unable to remove ${key} in url`);
        return;
      }
    },
    [isServerSide]
  );

  return { getItem, setItem, removeItem };
}
