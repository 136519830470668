import { useCallback, useRef } from 'react';
import { useBraze } from '@/components/Braze';

type Params = {
  dedupe?: boolean;
};
export const useBrazeForScreener = ({ dedupe }: Params = {}) => {
  const { logCustomEvent } = useBraze();
  const ref = useRef(new Set());

  const logScreenerCustomEvent = useCallback(
    (eventName: string) => {
      const shouldLog = !dedupe || !ref.current.has(eventName);
      if (shouldLog) {
        ref.current.add(eventName);
        logCustomEvent(eventName);
      }
    },
    [dedupe, logCustomEvent]
  );

  return { logScreenerCustomEvent };
};
