import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import DocumentHeader from '@components/DocumentHeader';
import track, { useTracking } from 'react-tracking';
import If from '@/components/If';
import { FilterTypes, SCREENER_RULES_PERSISTED_KEY } from './constants';
import debounce from 'lodash/debounce';

import type { ParsedRule } from './functions';
import {
  parseRules,
  convertIndustryIdToName,
  fixRulePayload,
  getFilters,
  getIndustries,
  getMarketLabel,
  getMarkets,
  getSnowflakeValueFromRule,
  getSortingRule,
  updateSortByRule,
  updateSortDirectionRule,
  applySortingAndFiltering,
  validateRulesString,
  setDescriptionRule,
  getKeywordsFromRules,
  updateMarketRule,
  updateIndustryRule,
} from './functions';
import { flow } from 'lodash';
import SecondaryHeader from './components/SecondaryHeader';
import ModalFilterEditor from './components/ModalFilterEditor';
import { LoadingWarren, Box, Text } from '@simplywallst/ui-core';
import { EditAndSaveScreenerModal } from './components/EditAndSaveScreenerModal';
import loadable from '@loadable/component';
import type { Industry } from '@/hooks/useIndustryTree';
import { useUserAccess } from '@/hooks/useUserAccess';
import { useQueryStringParam } from '@/hooks/useQueryStringParam';
import { useGridView, useUserGridList } from '@/hooks/useGridView';
import { useUserScreeners } from '@/hooks/useGridView/useUserScreeners';
import { useGqlCreateScreener } from '@/hooks/useGridView/useGqlCreateScreener';
import { useGqlDeleteScreener } from '@/hooks/useGridView/useGqlDeleteScreener';
import { useGqlUpdateScreener } from '@/hooks/useGridView/useGqlUpdateScreener';
import { useGqlUserScreener } from '@/hooks/useGridView/useGqlUserScreener';
import { useIndustryTree } from '@/hooks/useIndustryTree';
import { useReduxStateForScreener } from './hooks/useReduxStateForScreener';
import useUser from '@/hooks/useUser';
import {
  getDefaultRule,
  getScreenerName,
  shouldShowErrorLimit,
  shouldShowPlanInline,
  checkRulesDirty,
  createFilterLabel,
  transformGridListGqlData,
  transformUpdateCreateParams,
  extractErrorMessage,
} from './utils';
import { transformUserScreenerGqlData } from '@/utilities/screener';
import type { UpdateCreateScreenerParams } from './utils';

import { SWS_HOST } from '@/constants';
import { usePersistentStorage } from '@/hooks/usePersistentStorage';
import ScrollLoader from '@/components/ScrollLoader';
import useSnackbarToggle from '@/features/snackbar/hooks/useSnackbarToggle';
import { useSnackbar } from '@/features/snackbar';
import PopularScreens from './components/PopularScreens';
import PopularScreensSlider from './components/PopularScreens/PopularScreensSlider';
import { useScreenerQuery } from './hooks/useScreenerQuery';
import { useIsSmallScreen } from '@/hooks/useMedia';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';
import { ErrorLimit } from '@/pages/Stocks/components/ErrorLimit';
import { useInvalidateUserPredefinedScreenersQuery } from '@/hooks/useInfiniteCompanyScreenersQuery';
import { Filters } from './components/Filters';
import { OneColumn } from '@/components/Layout';
import { useLocation } from '@/hooks/useLocation';
import Tags from './components/Tags';
import RefreshOutline from '@simplywallst/ui-core/icons/RefreshOutline';
import { getUpdatedFieldsRules } from './components/ModalFilterEditor/util';
import { useBrazeForScreener } from './hooks/useBrazeForScreener';
import { useFlag } from '@simplywallst/unleash';
import { Footer } from '@/components/Footer';
import Header from './components/Header';

const TileList = loadable(() => import('@/components/TileList'), {
  resolveComponent: (c) => c.TileList,
});

const StockTable = loadable(
  () => import('@/pages/Stocks/components/StockTable')
);

const GuestRegistrationModal = loadable(
  () => import(/* webpackPrefetch: true */ '@components/GuestRegistrationModal')
);

const GuestRegistrationModalOnScroll = loadable(
  () => import('@/components/GuestRegistrationModalOnScroll')
);

const PlansInline = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@/components/OurPlans/components/PlansInline/PlansInline'
    )
);

export interface GridCompany {
  canonicalUrl: string;
  companyId: string;
  description: string;
  exchangeSymbol: string;
  name: string;
  slug: string;
  snowflakePoints: [number, number, number, number, number];
  tickerSymbol: number | string;
  uniqueSymbol: string;
  mainThumb: string;
}

export interface UserView {
  id: string;
  name: string;
  rules: string;
  slug: string;
  label: string;
  option: string;
  modifyDate: number | null;
  description: string;
}

export interface Props {
  industryList: Record<string, Industry>[];
}

export interface State {
  showSave: boolean;
  isEditingFilter: boolean;
  showSaveDialog: boolean;
  showConfirmDelete: boolean;
  showEditName: boolean;
  activeEditType:
    | FilterTypes.additional
    | FilterTypes.industry
    | FilterTypes.market
    | null;
}

type StocksView = 'table' | 'tile';

const PIXEL_LIMIT_SMALL_SCREEN = 500;
const PIXEL_LIMIT_LARGE_SCREEN = 300;

const Screener = () => {
  /**
   * Redux
   */
  const {
    id = '0',
    marketISO,
    market = 'global',
    industrySlug,
    industryName,
    demonymCountry,
    navigateTo,
    preFetchCompany,
  } = useReduxStateForScreener();
  /**
   *
   */

  const { addSnackbar } = useSnackbar();

  const { trackEvent } = useTracking();
  const {
    data: user,
    mutate: mutateUser,
    isFetched: isUserFetched,
  } = useUser();
  const { invalidateQuery: invalidateSavedScreenerQuery } =
    useInvalidateUserPredefinedScreenersQuery();

  const {
    data: { planType },
  } = useUserAccess();

  const { enabled, ready } = useFlag('mono-screener-grid-gql');

  const isUsingGridGql = enabled && ready;

  const { data: industryTree } = useIndustryTree();

  const isGuestUser = !user.loggedIn;

  const [currentRules, setCurrentRules] = useState<string | undefined>();
  const [saveScreenModalTitle, setSaveScreenModalTitle] =
    useState<string>('Save Screener');

  const location = useLocation();
  const [returnRecentCompaniesOnly, setReturnRecentCompaniesOnly] = useState(
    location?.query?.newCompanies === 'true' ? true : false
  );

  const { data: userGridListRestData } = useUserGridList({
    enabled: !isUsingGridGql && isGuestUser === false,
  });

  const { data: userGridListGqlData } = useUserScreeners(
    {
      input: { limit: 300, offset: 0 },
    },
    { enabled: isUsingGridGql && isGuestUser === false }
  );

  const userGridList = useMemo(() => {
    if (isUsingGridGql) {
      return transformGridListGqlData(userGridListGqlData);
    }
    return userGridListRestData;
  }, [isUsingGridGql, userGridListGqlData, userGridListRestData]);

  const [stocksView, setStocksView] = useState<StocksView>();

  useEffect(() => {
    if (isUserFetched && typeof stocksView === 'undefined' && user.loggedIn) {
      setStocksView(user.defaultStocksView);
    }
  }, [isUserFetched, user, stocksView]);

  const { getItem } = useQueryStringParam();

  const {
    data: gridViewRestData,
    status: gridViewStatus,
    createGrid: createGridRest,
    mutateGrid: updateGridRest,
    deleteGrid: deleteGridRest,
    creationStatus: creationStatusRest,
    mutationStatus: updateStatusRest,
    deletionStatus: deletionStatusRest,
  } = useGridView({
    id,
    onCreate: ({ id, slug }) => {
      navigateTo(id, slug);
    },
    onDelete: () => {
      navigateTo();
    },
    enabled: !isUsingGridGql && id !== '0' && id !== 'current',
  });

  const { data: gridViewGqlData } = useGqlUserScreener(
    { id: parseFloat(id) },
    {
      enabled: isUsingGridGql && id !== '0' && id !== 'current',
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const gridViewData = isUsingGridGql
    ? transformUserScreenerGqlData(gridViewGqlData)
    : gridViewRestData;

  const { name, rules } = gridViewData;
  const description = gridViewData?.description || '';

  const { mutate: deleteGridGql, status: deletionStatusGql } =
    useGqlDeleteScreener({
      onDelete: () => {
        navigateTo();
      },
    });

  const deletionStatus = isUsingGridGql
    ? deletionStatusGql
    : deletionStatusRest;

  const deleteGrid = useCallback(
    (id: string) => {
      if (isUsingGridGql) {
        deleteGridGql({ id: Number(id) });
      } else {
        deleteGridRest(id);
      }
    },
    [deleteGridGql, deleteGridRest, isUsingGridGql]
  );

  const { mutate: createGridGql, status: creationStatusGql } =
    useGqlCreateScreener({
      onCreate: ({ id, slug }) => {
        navigateTo(id, slug);
      },
      onError: (err) => {
        const errorMessage = extractErrorMessage(err);
        addSnackbar({
          type: 'negative',
          message: errorMessage || 'An error occurred',
          lifeTime: 3000,
        });
      },
    });

  const creationStatus = isUsingGridGql
    ? creationStatusGql
    : creationStatusRest;

  const createGrid = useCallback(
    (params: UpdateCreateScreenerParams) => {
      if (isUsingGridGql) {
        createGridGql(transformUpdateCreateParams(params));
      } else {
        createGridRest(params);
      }
    },
    [createGridGql, createGridRest, isUsingGridGql]
  );

  const { mutate: updateGridGql, status: updateStatusGql } =
    useGqlUpdateScreener({
      onError: (err) => {
        const errorMessage = extractErrorMessage(err);
        addSnackbar({
          type: 'negative',
          message: errorMessage || 'An error occurred',
          lifeTime: 3000,
        });
      },
    });

  const mutationStatus = isUsingGridGql ? updateStatusGql : updateStatusRest;

  const mutateGrid = useCallback(
    (params: UpdateCreateScreenerParams) => {
      if (isUsingGridGql) {
        const transformedParams = transformUpdateCreateParams(params);
        updateGridGql({
          id: Number(id),
          input: transformedParams.input,
        });
      } else {
        updateGridRest(params);
      }
    },
    [id, isUsingGridGql, updateGridGql, updateGridRest]
  );

  const isSavingScreener = [creationStatus, mutationStatus].includes('pending');
  useSnackbarToggle(isSavingScreener, {
    type: 'loading',
    message: 'Your screener is being saved',
  });

  const initialRulesRef = useRef<string | undefined>();

  const { set, get, flush } = usePersistentStorage();

  const getPersistedRules = useCallback(async () => {
    const persistedRules = await get(SCREENER_RULES_PERSISTED_KEY);
    if (validateRulesString(persistedRules)) {
      return persistedRules;
    }
    return undefined;
  }, [get]);

  const { logScreenerCustomEvent } = useBrazeForScreener({ dedupe: true });

  useEffect(() => {
    logScreenerCustomEvent('screener_visit');
  });

  useEffect(() => {
    const setInitialRules = async () => {
      if (typeof industryTree === 'undefined') return;

      const options = {
        marketISO,
        industrySlug,
        industries: industryTree,
      };

      if (!id || id === '0') {
        const persistedRules = await getPersistedRules();
        if (persistedRules) {
          options['rules'] = persistedRules;
        }
      }

      if (typeof rules !== 'undefined' && rules) {
        options['rules'] = rules;
      }

      if (id && id !== '0' && !rules && gridViewStatus !== 'success') {
        return;
      }

      const updatedRules = getDefaultRule(options);
      initialRulesRef.current = updatedRules;
      setCurrentRules(updatedRules);
    };

    setInitialRules();
  }, [
    rules,
    marketISO,
    industrySlug,
    industryTree,
    id,
    get,
    getPersistedRules,
    gridViewStatus,
  ]);

  useEffect(() => {
    const returnRecentCompaniesValue = location?.query?.newCompanies === 'true';
    setReturnRecentCompaniesOnly(returnRecentCompaniesValue);
  }, [id]);

  useEffect(() => {
    if (currentRules) {
      set(SCREENER_RULES_PERSISTED_KEY, currentRules);
    }
  }, [currentRules, set]);

  const pageSize = planType === 'free' ? 4 : 24;

  const isRulesDirty = checkRulesDirty(initialRulesRef.current, currentRules);

  const {
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingCompanies,
    isFetched,
    totalCompanies,
    companies,
    newCompaniesCount,
    isRestricted,
    restrictedReason,
    isPlanRestriction,
    maxResultCount,
  } = useScreenerQuery({
    rules: currentRules,
    pageSize,
    returnRecentCompaniesOnly,
    gridViewId: parseFloat(id),
    isRulesDirty,
    onError: (error) => {
      addSnackbar({
        type: 'negative',
        message: error?.message || 'An error occurred',
        lifeTime: 5000,
      });
    },
  });

  const pageTitle =
    id === '0'
      ? `Screener - Screen and Filter Stocks in all Markets`
      : `Screener - ${name}`;
  const screenerName = getScreenerName(name, demonymCountry, industryName);

  const isIdInUserList = userGridList.map((n) => n.id).includes(id);
  const screenerIsNewAndUnsaved = id === '0' || !isIdInUserList;
  const userViews = useMemo(
    () =>
      userGridList.map((n) => ({
        ...n,
        option: n.id,
        label: n.name,
      })),
    [userGridList]
  );

  // state hooks

  const [showRegModal, setShowRegModal] = useState(false);

  const parsedRules = parseRules(currentRules || '');

  const sortingRules = getSortingRule(parsedRules);
  const {
    valueScore: value,
    futurePerformanceScore: future,
    pastPerformanceScore: past,
    healthScore: health,
    dividendScore: dividends,
  } = getSnowflakeValueFromRule(parsedRules);

  const markets = getMarkets(parsedRules);
  const combinedMarkets = [
    ...markets.countries,
    ...markets.exchangeSymbols,
  ].map(getMarketLabel);

  const industries = getIndustries(parsedRules);
  const combinedIndustries = [
    ...industries.primary,
    ...industries.secondary,
    ...industries.tertiary,
  ].map((n) => convertIndustryIdToName(n, industryTree));
  const additionalFilters = getFilters(parsedRules);
  const sortByParam = getItem('sortBy');
  const directionParam = getItem('direction');

  const handleFilterChange = (newRules: string) => {
    const updatedRule = fixRulePayload(newRules);
    setCurrentRules(updatedRule);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSnowflakeChange = useCallback(
    debounce((points: number[]) => {
      if (typeof currentRules === 'undefined') return;
      const [value, future, past, health, dividend] = points;
      const serialisedRules = parseRules(currentRules);
      const modifiedRules = serialisedRules.map((rule) => {
        switch (rule[0]) {
          case 'value_score':
            return [rule[0], rule[1], value];
          case 'future_performance_score':
            return [rule[0], rule[1], future];
          case 'past_performance_score':
            return [rule[0], rule[1], past];
          case 'health_score':
            return [rule[0], rule[1], health];
          case 'dividends_score':
            return [rule[0], rule[1], dividend];
          default:
            return rule;
        }
      });
      trackEvent({
        action: 'drag',
        subject: 'snowflake',
        properties: points.join(','),
      });
      setCurrentRules(JSON.stringify(modifiedRules));
    }, 800),
    [currentRules, trackEvent]
  );

  const showFilterModal = (type: string) => {
    setIsEditingFilter(true);
    setActiveEditType(type);
  };

  /** Apply sorting when provided in the query string */
  useEffect(() => {
    if (typeof currentRules === 'undefined') return;
    if (directionParam && sortByParam) {
      const updatedRules = applySortingAndFiltering(
        directionParam,
        sortByParam,
        JSON.parse(currentRules) as ParsedRule[]
      );

      setTimeout(() => {
        handleFilterChange(JSON.stringify(updatedRules));
      }, 500);

      window.scrollTo(0, 0);
    }
  }, [directionParam, sortByParam, currentRules]);

  /** states */
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [isEditingFilter, setIsEditingFilter] = useState(false);
  const [activeEditType, setActiveEditType] = useState('');

  const handleViewSelect = useCallback(
    (value: StocksView) => {
      setStocksView(value);
      if (user.loggedIn) {
        mutateUser({
          settings: {
            stocks_view: value,
          },
        });
      }
    },
    [mutateUser, user]
  );

  const { isMobile } = useUserAgent();
  const isSmallScreen = useIsSmallScreen({ defaultMatches: isMobile });
  const guestModalPixelLimit = useMemo(() => {
    if (isSmallScreen) {
      return PIXEL_LIMIT_SMALL_SCREEN;
    }
    return PIXEL_LIMIT_LARGE_SCREEN;
  }, [isSmallScreen]);

  const isShowingTable =
    typeof stocksView === 'undefined' || stocksView === 'table';

  const onOnlyRecentSearchesUpdate = useCallback(
    (value: boolean) => {
      if (isGuestUser) {
        setShowRegModal(true);
        return;
      }
      if (screenerIsNewAndUnsaved && planType !== 'free') {
        setSaveScreenModalTitle('Save Screener To See New Companies');
        setShowSaveDialog(true);
        return;
      }
      setReturnRecentCompaniesOnly(value);
    },
    [planType, screenerIsNewAndUnsaved]
  );

  const isShowingPlansInline = shouldShowPlanInline({
    isFetchingCompanies,
    planType,
    isPlanRestriction,
  });
  const isHidingData = isRestricted && returnRecentCompaniesOnly;
  const isShowingErrorLimit = shouldShowErrorLimit({
    isRestricted,
    returnRecentCompaniesOnly,
    isShowingPlansInline,
  });

  const restrictionReason = useCallback(() => {
    if (isPlanRestriction && planType !== 'free')
      return 'You have reached the 3/3 saved screeners limit.';
    return 'Screener is only available on Premium and Unlimited Plans.';
  }, [isPlanRestriction, planType]);

  const mappedScore = useMemo(() => {
    return [value, future, past, health, dividends].map((score) =>
      Number(Math.min(score as number, 6))
    );
  }, [value, future, past, health, dividends]);

  const handleKeywordChange = useCallback(
    (keywords: string) => {
      if (typeof currentRules === 'string') {
        const modifiedRules = setDescriptionRule(currentRules, keywords);
        setCurrentRules(modifiedRules);
      }
    },
    [currentRules]
  );

  const debouncedHandleKeywordChange = useCallback(
    debounce(handleKeywordChange, 800),
    [handleKeywordChange]
  );

  const keywords = useMemo(() => {
    const descriptionRule = getKeywordsFromRules(currentRules);
    if (
      typeof descriptionRule !== 'string' &&
      'description' in descriptionRule &&
      typeof descriptionRule.description === 'string'
    ) {
      return descriptionRule.description;
    }
    return '';
  }, [currentRules]);

  const onSaveScreener = () => {
    if (isGuestUser) {
      setShowRegModal(true);
      return;
    }
    setSaveScreenModalTitle('Save Screener');
    setShowSaveDialog(true);
  };

  const onChangeUserView = (viewId?: string, viewSlug?: string) => {
    const willChangeToCreateNewScreener = viewId === '0' || !viewId;
    const shouldSaveScreener =
      screenerIsNewAndUnsaved && willChangeToCreateNewScreener;

    if (shouldSaveScreener) {
      onSaveScreener();
      return;
    }

    if (willChangeToCreateNewScreener) {
      flush(SCREENER_RULES_PERSISTED_KEY);
    }
    navigateTo(viewId, viewSlug);
  };

  return (
    <OneColumn>
      <DocumentHeader
        title={pageTitle}
        description="Use our stock screener to filter companies by market cap, PE ratio, Earnings growth or our unique Snowflake"
        allowCrawlers={id === '0'} // `/screener/create` only
      >
        <link rel="canonical" href={`${SWS_HOST}/screener/create`} />
      </DocumentHeader>
      <Header
        isPending={[creationStatus, mutationStatus, deletionStatus].includes(
          'pending'
        )}
        screenerName={screenerName}
        currentUserViewId={id}
        userViews={userViews}
        onDelete={() => {
          deleteGrid(id);
          navigateTo();
        }}
        onChangeUserView={onChangeUserView}
        onReset={() => setCurrentRules(rules || getDefaultRule())}
        onSaveScreenerClick={onSaveScreener}
        onUpdate={({ description, name }) => {
          if (isGuestUser) {
            setShowRegModal(true);
            return;
          }
          if (typeof currentRules === 'undefined') return;
          invalidateSavedScreenerQuery();
          mutateGrid({
            description,
            name,
            rules: currentRules,
          });
        }}
      />
      <PopularScreens>
        <PopularScreensSlider
          setCurrentRules={setCurrentRules}
          currentRules={currentRules}
        />
      </PopularScreens>
      <Filters
        snowflakeScore={mappedScore}
        onSnowflakeChange={handleSnowflakeChange}
        onKeywordChange={debouncedHandleKeywordChange}
        keywords={keywords}
        filters={[
          <Box
            key="markets"
            borderRadius="x1spacePx"
            padding="x1spacePx"
            paddingMd="x2spacePx"
            onClick={() => showFilterModal(FilterTypes.market)}
            className="relative cursor-pointer bg-[--s-color-surface-2]"
          >
            <Text typography="xSmallStrong">Markets</Text>
            <Text typography="tiny" color="brand01">
              {createFilterLabel(combinedMarkets, 'market')}
            </Text>
            <div className="hidden md:block">
              <Tags
                list={combinedMarkets.slice(0, 5)}
                concat={combinedMarkets.length >= 5}
                onClick={() => showFilterModal(FilterTypes.market)}
              />
            </div>
            {combinedMarkets.length > 0 && (
              <RefreshOutline
                className="absolute right-2 top-3 hidden fill-current text-brand-01 transition-transform duration-500 ease-in-out hover:-rotate-180 md:block"
                onClick={(e) => {
                  e.stopPropagation();
                  if (typeof currentRules === 'undefined') return;
                  const newRules = updateMarketRule(
                    [],
                    [],
                    parseRules(currentRules)
                  );
                  setCurrentRules(JSON.stringify(newRules));
                }}
              />
            )}
          </Box>,
          <Box
            key="industries"
            borderRadius="x1spacePx"
            padding="x1spacePx"
            paddingMd="x2spacePx"
            onClick={() => showFilterModal(FilterTypes.industry)}
            className="relative cursor-pointer bg-[--s-color-surface-2]"
          >
            <Text typography="xSmallStrong">Industries</Text>
            <Text typography="tiny" color="brand01">
              {createFilterLabel(combinedIndustries, 'industry')}
            </Text>
            <div className="hidden md:block">
              <Tags
                list={combinedIndustries.slice(0, 5)}
                concat={combinedIndustries.length >= 5}
                onClick={() => showFilterModal(FilterTypes.industry)}
              />
            </div>
            {combinedIndustries.length > 0 && (
              <RefreshOutline
                className="absolute right-2 top-3 hidden fill-current text-brand-01 transition-transform duration-500 ease-in-out hover:-rotate-180 md:block"
                onClick={(e) => {
                  e.stopPropagation();
                  if (typeof currentRules === 'undefined') return;
                  const newRules = updateIndustryRule(
                    [],
                    [],
                    [],
                    parseRules(currentRules)
                  );

                  const updatedRule = fixRulePayload(JSON.stringify(newRules));
                  setCurrentRules(updatedRule);
                }}
              />
            )}
          </Box>,
          <Box
            key="metrics"
            borderRadius="x1spacePx"
            padding="x1spacePx"
            paddingMd="x2spacePx"
            onClick={() => showFilterModal(FilterTypes.additional)}
            className="relative cursor-pointer bg-[--s-color-surface-2]"
          >
            <Text typography="xSmallStrong">Metrics</Text>
            <Text typography="tiny" color="brand01">
              {createFilterLabel(additionalFilters, 'filter')}
            </Text>
            <div className="hidden md:block">
              <Tags
                list={additionalFilters.slice(0, 6)}
                concat={additionalFilters.length >= 6}
                onClick={() => showFilterModal(FilterTypes.additional)}
              />
            </div>
            {additionalFilters.length > 1 && (
              <RefreshOutline
                className="absolute right-2 top-3 hidden fill-current text-brand-01 transition-transform duration-500 ease-in-out hover:-rotate-180 md:block"
                onClick={(e) => {
                  e.stopPropagation();
                  if (typeof currentRules === 'undefined') return;
                  const newRules = getUpdatedFieldsRules(currentRules, [], {});
                  newRules.push(['primary_flag', '=', true]);
                  handleFilterChange(JSON.stringify(newRules));
                }}
              />
            )}
          </Box>,
        ]}
      />
      <SecondaryHeader
        isQueryRestricted={isRestricted}
        isCalculating={isFetchingCompanies}
        companiesCount={totalCompanies}
        newCompaniesCount={newCompaniesCount ?? 0}
        sortingRule={sortingRules?.sortBy ?? ''}
        onViewSelect={handleViewSelect}
        stocksView={stocksView || 'table'}
        returnRecentCompaniesOnly={returnRecentCompaniesOnly}
        onSortingUpdate={(sortBy: string) => {
          const updateSortBy = flow([
            updateSortByRule(sortBy),
            handleFilterChange,
          ]);
          updateSortBy(parsedRules);
        }}
        directionRule={sortingRules?.direction ?? ''}
        onDirectionUpdate={(direction: string) => {
          const updateSortDirection = flow([
            updateSortDirectionRule(direction),
            handleFilterChange,
          ]);
          updateSortDirection(parsedRules);
        }}
        onOnlyRecentSearchesUpdate={onOnlyRecentSearchesUpdate}
        maxResultCount={maxResultCount}
      />
      <div className="px-4 lg:px-0">
        <ScrollLoader
          handleLoadRequest={() => {
            if (
              !isFetchingCompanies &&
              hasNextPage &&
              companies.length &&
              planType !== 'free'
            ) {
              fetchNextPage();
            }
          }}
          muteScrollEvent={isGuestUser}
        >
          {() => {
            if (isHidingData) {
              return null;
            }
            if (isShowingTable) {
              return (
                <StockTable
                  companies={companies}
                  industry={industryName}
                  market={market}
                  isFetching={isFetchingCompanies}
                  isFetched={isFetched}
                  onLongHover={preFetchCompany}
                  hideIndustryCol={false}
                />
              );
            }
            return (
              <TileList
                companies={companies}
                onLongHover={preFetchCompany}
                isFetching={isFetchingCompanies}
                isFetched={isFetched}
              />
            );
          }}
        </ScrollLoader>
      </div>
      {isShowingErrorLimit && <ErrorLimit reason={restrictedReason} />}
      {isEditingFilter && currentRules && industryTree && (
        <ModalFilterEditor
          type={activeEditType}
          rules={currentRules}
          industryList={industryTree}
          onChange={handleFilterChange}
          onClose={() => setIsEditingFilter(false)}
          onConfirm={(updatedRuleJson) => {
            if (updatedRuleJson) {
              handleFilterChange(updatedRuleJson);
            }
            setIsEditingFilter(false);
          }}
        />
      )}

      <If match={showSaveDialog}>
        <EditAndSaveScreenerModal
          title={saveScreenModalTitle}
          onClose={() => setShowSaveDialog(false)}
          onConfirm={(viewName, viewDescription) => {
            if (typeof currentRules === 'undefined') return;
            setShowSaveDialog(false);
            trackEvent({
              action: 'click',
              subject: 'edit_and_save_screener_modal',
              modifier: 'confirm',
            });
            createGrid({
              rules: currentRules,
              name: viewName,
              description: viewDescription,
            });
          }}
          oldName={`Copy of ${screenerName}`}
          oldDescription={description}
          confirmLabel="Confirm"
        />
      </If>

      <If match={isShowingPlansInline}>
        <Box marginTop="x2" marginTopLg="x4">
          <PlansInline
            limitHitterEventProperties={{
              trigger: 'screener',
              screener_name: name,
            }}
          >
            <Box
              paddingTop="x5"
              paddingBottom="x4"
              paddingRight="x3"
              paddingLeft="x3"
            >
              <Text
                typography="baseStrong"
                color="warning"
                data-cy-id="plan-restriction"
              >
                {restrictionReason()}
              </Text>
            </Box>
            <h2>
              {RUNTIME_ENV === 'native'
                ? 'The Stock Screener can be accessed via our other plans.'
                : 'Upgrade to access the Stock Screener.'}
            </h2>
          </PlansInline>
        </Box>
      </If>
      <If match={isFetchingCompanies}>
        <LoadingWarren />
      </If>

      <If match={showRegModal}>
        <GuestRegistrationModal
          canClose={true}
          onClose={() => {
            trackEvent({
              action: 'click',
              modifier: 'cancelRegistration',
            });
            setShowRegModal(false);
          }}
          onRegisterSuccess={() => {
            trackEvent({
              type: 'track',
              subject: 'stockScreener',
              modifier: 'registration',
              action: 'success',
            });
          }}
        />
      </If>

      <If match={isGuestUser}>
        <GuestRegistrationModalOnScroll pixelLimit={guestModalPixelLimit} />
      </If>

      <Footer />
    </OneColumn>
  );
};

export default track(
  {
    type: 'track',
    action: 'landed',
    subject: 'page',
  },
  { dispatchOnMount: true }
)(Screener);
