import type { ReactNode, ChangeEvent } from 'react';
import track from 'react-tracking';
import TickSolid from '@simplywallst/ui-core/icons/TickSolid';
import { CheckboxLabel, CheckboxWrapper, CheckboxBase } from './styled';

export type Props = Tracking & {
  name?: string;
  label?: ReactNode;
  value: boolean;
  invert?: boolean;
  readOnly?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  display?: 'block' | 'inline-block'; // add more if needed. if it becomes unmaintainable separate into a different type
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  trackingProps?: Record<string, string | number>;
};

const Checkbox = ({
  display,
  name,
  value,
  label,
  invert,
  readOnly,
  disabled,
  tabIndex,
  onChange,
  autoFocus,
  tracking: { trackEvent },
}: Props) => (
  <CheckboxWrapper disabled={disabled} invert={invert}>
    <CheckboxLabel displayType={display as string}>
      {label}
      <input
        name={name}
        type="checkbox"
        tabIndex={tabIndex || 0}
        onChange={(e) => {
          onChange && onChange(e);
          trackEvent({
            action: 'checkbox',
            active: e.target.checked,
          });
        }}
        autoFocus={autoFocus}
        checked={value}
        disabled={disabled}
        readOnly={readOnly}
      />
      <CheckboxBase invert={invert}>
        <TickSolid />
      </CheckboxBase>
      <span />
    </CheckboxLabel>
  </CheckboxWrapper>
);

Checkbox.defaultProps = {
  label: '',
  disabled: false,
  display: 'inline-block',
};

export default track(({ name, trackingProps }) => ({
  type: 'track',
  modifier: name || undefined,
  ...trackingProps,
}))(Checkbox);
