import { useTracking } from 'react-tracking';
import { Modal } from '@simplywallst/ui-core';
import SelectFields from '../../../SelectFields';

export interface AdvancedFilterModalProps {
  rules: string;
  onDeselect: (type: 'filter') => void;
  onConfirm: () => void;
  onChange: (
    toggledMoreFilters: string[],
    toggledRule: Record<string, string>
  ) => void;
  onClose: () => void;
}

const AdvancedFilterModal = ({
  onClose,
  onDeselect,
  onConfirm,
  rules,
  onChange,
}: AdvancedFilterModalProps) => {
  const { Track } = useTracking({
    type: 'track',
    subject: 'AdvancedFilterModal',
  });
  return (
    <Track>
      <Modal
        title="Advanced filters"
        onPrimaryClick={onConfirm}
        secondaryLabel="Clear selections"
        onSecondaryClick={() => onDeselect('filter')}
        primaryLabel="Done"
        onClose={onClose}
      >
        <SelectFields rules={rules} onChange={onChange} />
      </Modal>
    </Track>
  );
};

export default AdvancedFilterModal;
