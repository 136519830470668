import { useMemo, useEffect } from 'react';
import { companyGqlMapper } from '@/hooks/useInfiniteCompanyScreenersQuery/utils';
import { toCurrency } from '@/utilities/formatting';
import { getIsRestricted, getRestrictionReason } from './utils';
import { checkIsPlanRestriction } from '@/hooks/useInfiniteCompanyScreenersQuery/utils';
import { useInfiniteScreenersQuery } from './useInfiniteScreenersQuery';
import { useUserAccess } from '@/hooks/useUserAccess';

type ScreenerQueryParams = {
  gridViewId?: number;
  rules?: string;
  pageSize: number;
  returnRecentCompaniesOnly?: boolean;
  isRulesDirty: boolean;
  onError?: (error) => void;
};

/* Should migrate it to use the gql tada for type safety */
export const useScreenerQuery = ({
  gridViewId,
  rules,
  pageSize,
  returnRecentCompaniesOnly,
  isRulesDirty,
  onError,
}: ScreenerQueryParams) => {
  const {
    data: dataGQL,
    fetchNextPage: fetchNextPageGQL,
    hasNextPage: hasNextPageGQL,
    isFetching: isFetchingCompaniesGQL,
    isFetched: isFetchedGQL,
    isError: isErrorGQL,
    error: errorGQL,
  } = useInfiniteScreenersQuery({
    gridViewId,
    rules,
    pageSize,
    returnRecentCompaniesOnly,
    isRulesDirty,
  });

  const enhancedFetchNextPageGQL = useMemo(() => {
    const shouldFetchNextPage = !isErrorGQL && !isFetchingCompaniesGQL;
    return shouldFetchNextPage ? fetchNextPageGQL : () => {};
  }, [fetchNextPageGQL, isErrorGQL, isFetchingCompaniesGQL]);

  const firstPage = dataGQL?.pages[0];
  const totalRecords = firstPage?.totalHits ?? 0;
  const totalCompaniesGQL = toCurrency(totalRecords || 0, 0).split('$')[1];

  const companiesGQL = useMemo(() => {
    const flattenCompanies =
      dataGQL?.pages
        .map((page) => page.companies)
        .flat()
        .filter(Boolean) || [];
    return flattenCompanies.map(companyGqlMapper);
  }, [dataGQL]);

  const searchRestrictions = firstPage?.meta?.searchRestrictions;
  const systemRestrictions = firstPage?.meta?.systemRestrictions;

  const isRestricted = getIsRestricted(searchRestrictions, systemRestrictions);
  const { data: uac } = useUserAccess();

  const restrictedReason = useMemo(() => {
    const numberOfViewableScreeners = uac?.numberOfViewableScreeners;
    const maxResultCount =
      systemRestrictions?.maxResultCountForRecentCompanyFeature;
    return getRestrictionReason({
      searchRestrictions,
      systemRestrictions,
      numberOfViewableScreeners,
      totalRecords,
      maxResultCount,
    });
  }, [
    searchRestrictions,
    systemRestrictions,
    totalRecords,
    uac?.numberOfViewableScreeners,
  ]);

  const isPlanRestriction = useMemo(
    () => checkIsPlanRestriction(searchRestrictions),
    [searchRestrictions]
  );

  useEffect(() => {
    if (isErrorGQL && onError) {
      onError?.(errorGQL);
    }
  }, [isErrorGQL, errorGQL]);

  return {
    companies: companiesGQL,
    fetchNextPage: enhancedFetchNextPageGQL,
    hasNextPage: hasNextPageGQL,
    isFetching: isFetchingCompaniesGQL,
    isFetched: isFetchedGQL,
    totalCompanies: totalCompaniesGQL,
    newCompaniesCount: firstPage?.newCompaniesTotalHits,
    isRestricted,
    restrictedReason,
    isPlanRestriction,
    maxResultCount:
      systemRestrictions?.maxResultCountForRecentCompanyFeature || null,
  };
};
