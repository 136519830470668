import { Container } from './styled';
import loadable from '@loadable/component';

const Filters = loadable(() => import('./components/Filters'), {
  resolveComponent: (module) => module.Filters,
});

interface Props {
  onChange: (id: string, rule: string) => void;
  onToggle: (toggledId: string, rule?: string) => void;
  toggledFilters: string[];
  rules: Record<string, string>;
}

export function MoreFiltersConfig({
  onToggle = () => null,
  onChange = () => null,
  toggledFilters = [],
  rules = {},
}: Props) {
  return (
    <Container>
      <Filters
        onChange={onChange}
        onToggle={onToggle}
        toggledFilters={toggledFilters}
        rules={rules}
      />
    </Container>
  );
}
