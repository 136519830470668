import styled from 'styled-components';
import { Grid, media } from '@simplywallst/ui-core';

export const MainGrid = styled(Grid)`
  gap: ${({ theme }) => theme.x1spacePx}px;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas:
    'snowflake filters'
    'keywords keywords';

  ${media.mdUp`
    grid-template-columns: 3fr 5fr;
    grid-template-rows: min-content auto;
    grid-template-areas:
      'snowflake filters'
      'snowflake keywords';
    row-gap: ${({ theme }) => theme.x2spacePx}px;  
  `}
`;

export const SnowflakeGrid = styled(Grid)`
  grid-area: snowflake;
`;

export const FiltersGrid = styled(Grid)`
  grid-area: filters;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  row-gap: ${({ theme }) => theme.x1spacePx}px;

  ${media.mdUp`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: ${({ theme }) => theme.x2spacePx}px;
    min-height: 241px;
  `}
`;

export const KeywordsGrid = styled(Grid)`
  grid-area: keywords;
  height: 60px;
`;
