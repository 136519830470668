import {
  EmblaButton,
  EmblaButtonNext,
  EmblaButtonPrev,
} from '@/components/Carousel/styled';
import styled, { css } from 'styled-components';

export const StyledEmbla = styled('div')`
  position: relative;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.x2}px;

  ${EmblaButton} {
    transition: all 0.3s ease-in-out;
    background: ${({ theme }) => theme.color.phantom} !important;
    width: ${({ theme }) => theme.x5spacePx}px;
    height: ${({ theme }) => theme.x5spacePx}px;
    border-radius: ${({ theme }) => theme.x5spacePx}px;

    > svg > * {
      fill: ${({ theme }) => theme.color.white};
    }

    &:disabled {
      opacity: 0;
    }
  }

  ${EmblaButtonNext} {
    right: 0;

    &:disabled {
      ${({ theme }) => css`
        right: -${theme.x5spacePx}px;
      `}
    }
  }

  ${EmblaButtonPrev} {
    left: 0;

    &:disabled {
      ${({ theme }) => css`
        left: -${theme.x5spacePx}px;
      `}
    }
  }
`;
