import { useState, useEffect } from 'react';
import FilterSearch from '../FilterSearch';
import type { IndustryTree } from '../../functions';
import { getIndustryRelationTree } from '../../functions';
import { mapIndustries, getUpdatedIndustries } from '../ModalFilterEditor/util';
import { ORDERED_INDUSTRIES } from '@/constants/industries';

interface Industry {
  label: string;
  slug?: string;
  children: string[];
}

interface Props {
  selectedIndustries: string[];
  industryList: Record<string, Industry>[];
  onChange: (
    updatedPrimaryIndustries: string[],
    updatedSecondaryIndustries: string[],
    updatedTertiaryIndustries: string[]
  ) => void;
}

// Generating an IndustryTree is expensive and should only be done once and on demand
let memoIndustryTree: IndustryTree;

const SelectIndustry = ({
  selectedIndustries,
  industryList,
  onChange,
}: Props) => {
  const [industries, setIndustries] = useState(selectedIndustries);
  const [industryTree, setIndustryTree] = useState<IndustryTree>({});
  useEffect(() => {
    if (!memoIndustryTree) {
      memoIndustryTree = getIndustryRelationTree(industryList);
    }
    setIndustryTree(memoIndustryTree);
  }, [industries]);
  return (
    <FilterSearch
      name="industry-filter-search"
      placeholder="Search e.g. 'Insurance'"
      selected={industries}
      onToggle={(industryId: string, depth: number) => {
        const { primary, secondary, tertiary } = mapIndustries(
          industries,
          industryList
        );
        const {
          updatedPrimaryIndustries,
          updatedSecondaryIndustries,
          updatedTertiaryIndustries,
        } = getUpdatedIndustries(
          industryId,
          depth,
          primary,
          secondary,
          tertiary,
          industryTree,
          [industryList[0], industryList[1]]
        );
        setIndustries([
          ...updatedPrimaryIndustries,
          ...updatedSecondaryIndustries,
          ...updatedTertiaryIndustries,
        ]);
        onChange(
          updatedPrimaryIndustries,
          updatedSecondaryIndustries,
          updatedTertiaryIndustries
        );
      }}
      order={ORDERED_INDUSTRIES}
      listing={industryList}
    />
  );
};

export default SelectIndustry;
