import { get } from "../methods";
function getUserViews(headers = {}) {
  return get({
    url: "api/grid/view/myviews",
    headers
    // requiresAuthentication: true,
  });
}
export {
  getUserViews as default
};
