import { useEffect, useState } from 'react';
import { Box, Text, Textfield, Grid } from '@simplywallst/ui-core';
import InformationSolid from '@simplywallst/ui-core/icons/InformationSolid';
import Tooltip from '@/components/Tooltip';

interface Props {
  keywords?: string;
  onChange: (keywords: string) => void;
}

export function KeywordSearch({ keywords, onChange }: Props) {
  const [value, setValue] = useState(keywords);

  useEffect(() => {
    setValue(keywords);
  }, [keywords]);

  return (
    <Box
      borderRadius="x1spacePx"
      padding="x1spacePx"
      paddingLeftMd="x2spacePx"
      paddingRightMd="x2spacePx"
      style={{
        backgroundColor: 'var(--s-color-surface-2)',
      }}
    >
      <Grid gap="12px" templateColumns="auto 1fr auto" alignItems="center">
        <Grid>
          <label htmlFor="keyword-search" style={{ marginBottom: 0 }}>
            <Text as="span" typography="xSmallStrong">
              Keywords
            </Text>
          </label>
        </Grid>
        <Grid>
          <Textfield
            id="keyword-search"
            type="search"
            placeholder="e.g. nuclear energy, lidar, palladium…"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </Grid>
        <Grid>
          <Tooltip title="Use keywords for products, ingredients, materials, themes or sub-industries. Use '|' to search for multiple options (e.g., keyword1 | keyword2 finds either one).">
            <a
              href={
                'https://support.simplywall.st/hc/en-us/articles/10991433774735-Screening-Stocks-Using-Keywords'
              }
              target="_blank"
              rel="noreferrer"
            >
              <InformationSolid className="fill-softer" />
            </a>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}
