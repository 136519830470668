import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  > div {
    &:first-child {
      h2 {
        margin-top: 0px;
      }
    }
  }
`;
