import { useState, useEffect } from 'react';
import { Heading } from '@simplywallst/ui-core';
import CrossSolid from '@simplywallst/ui-core/icons/CrossSolid';
import { usePersistentStorage } from '@/hooks/usePersistentStorage';

interface Props {
  children: React.ReactNode;
}

const HAS_DISMISSED_POPULAR_SCREENERS = 'has_dismissed_popular_screeners';

const PopularScreens = ({ children }: Props) => {
  const [showPopularScreens, setShowPopularScreens] = useState(true);
  const [hasDismissed, setHasDismissed] = useState(false);

  const { get, set } = usePersistentStorage();

  useEffect(() => {
    const getHasDismissed = async () => {
      const isDismissed = await get(HAS_DISMISSED_POPULAR_SCREENERS);
      if (isDismissed === 'true') {
        setHasDismissed(true);
      }
    };
    getHasDismissed();
  }, [get]);

  const handlePlanModalAction = () => {
    setShowPopularScreens(false);
    set(HAS_DISMISSED_POPULAR_SCREENERS, 'true');
  };

  if (!showPopularScreens || hasDismissed) {
    return null;
  }

  return (
    <div className="mb-4 rounded-md bg-legacy-noir p-4">
      <div className="grid grid-cols-[auto_1fr_auto] items-center">
        <Heading as="h2" typography="smallStrong">
          Popular Screens
        </Heading>
        <button
          type="button"
          onClick={handlePlanModalAction}
          className="z-1 justify-self-end"
        >
          <CrossSolid className="fill-softer" data-cy-id="close-button" />
        </button>
      </div>
      {children}
    </div>
  );
};

export default PopularScreens;
