import { patch } from "../methods";
function updateGrid(gridId, payload, headers = {}) {
  return patch({
    url: `api/grid/view/${gridId}`,
    headers,
    payload
  });
}
export {
  updateGrid as default
};
