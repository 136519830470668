import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';
import type { VariablesOf } from '@/utilities/gql';
import { produce } from 'immer';
import { useQueryClient } from '@tanstack/react-query';

import { getDefault } from './utils';
import type { GridView } from './utils';
import { useUserScreeners } from './useUserScreeners';
import { useGqlUserScreener } from './useGqlUserScreener';

const MUTATION = gql(
  `
  mutation updateScreener(
        $id: Int!
        $input: CreateScreenerInput!
      ) {
        updateScreener(
          id: $id
          input: $input
        ) {
          id
          name
          slug
          filterJson
          description
          onlyMarketFiltered
          imageUrl
          modifyDate
          status
          type {
            slug
          }
        }
      }
`
);

export type UpdateScreenerVariables = VariablesOf<typeof MUTATION>;

const useUpdateScreenerBase = makeGqlMutation(MUTATION);

type Options = {
  onError?: (err: unknown) => void;
};

export const useGqlUpdateScreener = ({ onError }: Options) => {
  const queryClient = useQueryClient();
  const queryKey = useGqlUserScreener.getAllOptions().queryKey;

  return useUpdateScreenerBase({
    onMutate: async ({ input }): Promise<{ prevGrid?: GridView }> => {
      const { name, filterJson: rules, description } = input;

      const prevGrid = queryClient.getQueryData<GridView>(queryKey);
      queryClient.setQueryData<GridView>(queryKey, (state = getDefault()) =>
        produce(state, (draft) => {
          draft.name = name;
          draft.rules = rules;
          draft.description = description || '';
        })
      );
      return {
        prevGrid,
      };
    },
    onError: (err, payload, context) => {
      const prevGrid = (context as { prevGrid?: GridView }).prevGrid;
      if (typeof prevGrid !== 'undefined') {
        queryClient.setQueryData<GridView>(queryKey, prevGrid);
      }
      if (typeof onError !== 'undefined') {
        onError(err);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      queryClient.invalidateQueries(useUserScreeners.getAllOptions());
    },
  });
};
