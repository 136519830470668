import { useTracking } from 'react-tracking';
import { Modal } from '@simplywallst/ui-core';
import SelectIndustry from '../../../SelectIndustry';

interface Industry {
  label: string;
  slug?: string;
  children: string[];
}

interface SelectedIndustries {
  primary: string[];
  secondary: string[];
  tertiary: string[];
}

export interface FilterByIndustryModalProps {
  selectedIndustries: SelectedIndustries;
  industryList: Record<string, Industry>[];
  onDeselect: (type: 'industry') => void;
  onConfirm: () => void;
  onChange: (
    updatedCountries: string[],
    updatedExchangeSymbols: string[],
    udaptedTertiaryIndustries: string[]
  ) => void;
  onClose: () => void;
}

const FilterByIndustryModal = ({
  onClose,
  onDeselect,
  onConfirm,
  industryList,
  selectedIndustries,
  onChange,
}: FilterByIndustryModalProps) => {
  const { Track } = useTracking({
    type: 'track',
    subject: 'FilterByIndustryModal',
  });
  return (
    <Track>
      <Modal
        title="Filter by Industry"
        onClose={onClose}
        onPrimaryClick={onConfirm}
        secondaryLabel="Clear selections"
        onSecondaryClick={() => onDeselect('industry')}
        primaryLabel="Done"
        fullscreen={true}
      >
        <SelectIndustry
          onChange={onChange}
          industryList={industryList}
          selectedIndustries={[
            ...selectedIndustries.primary,
            ...selectedIndustries.secondary,
            ...selectedIndustries.tertiary,
          ]}
        />
      </Modal>
    </Track>
  );
};

export default FilterByIndustryModal;
