import track from 'react-tracking';
import { Tag, Container } from './styled';

interface Props {
  list: string[];
  concat?: boolean;
  onClick?: () => void;
}

const Tags = ({
  list,
  concat,
  onClick,
  tracking: { trackEvent },
}: Props & Tracking) => (
  <Container>
    {list.slice(0, 16).map(
      (item, index) =>
        /** Industry list has duplicate banks label, once that is fixed, use item as key instead of index */
        item !== undefined && (
          <Tag
            key={index}
            onClick={() => {
              onClick && onClick();
              trackEvent({
                action: 'click',
                subject: 'pill',
                label: item,
              });
            }}
          >
            {item}
          </Tag>
        )
    )}
    {concat ? (
      <Tag key={'ellipsis'} onClick={onClick}>
        ...
      </Tag>
    ) : null}
  </Container>
);

export default track({
  type: 'track',
})(Tags);
