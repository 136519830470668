import { get } from "../methods";
function getIndustries_default(options) {
  const url = "api/industry/tree";
  return get({
    url,
    payload: typeof options !== "undefined" && Object.keys(options).length > 0 ? {
      filter_child_level: options?.filterChildLevel,
      country: options?.country
    } : void 0
  });
}
export {
  getIndustries_default as default
};
