import type {
  SearchRestrictions,
  SystemRestrictions,
} from '@/hooks/useInfiniteCompanyScreenersQuery/queryFn';

type SearchRestrictionsCode =
  NonNullable<SearchRestrictions>['restrictionCode'];
type SystemRestrictionsCode =
  NonNullable<SystemRestrictions>['restrictionCode'];

export const getIsRestricted = (
  searchRestrictions?: SearchRestrictions | null,
  systemRestrictions?: SystemRestrictions | null
) => {
  return searchRestrictions?.isRestricted || systemRestrictions?.isRestricted;
};

type RestrictionsMap = Partial<
  Record<NonNullable<SearchRestrictionsCode | SystemRestrictionsCode>, string>
>;

const REASON_MAPPING: RestrictionsMap = {
  PLAN_LIMIT_COMPANY_COUNT:
    'Viewing new companies is only available on paid plans. Upgrade to view new companies for this screener.',
  USER_IS_GUEST:
    'Viewing new companies is only available on paid plans. Upgrade to view new companies for this screener.',
  PLAN_LIMIT_NEW_COMPANY:
    'Filtering by new stocks is only available on paid plans. Upgrade now to view new companies in this screener.',
  USER_NOT_SCREENER_OWNER: `You're don't own this screener. Some functionality may be limited.`,
  PIT_SEARCH_OFF: 'Oops! Something went wrong. Try again later.',
};

type GetRestrictionReasonParams = {
  searchRestrictions: SearchRestrictions | null | undefined;
  systemRestrictions: SystemRestrictions | null | undefined;
  numberOfViewableScreeners: number | null | undefined;
  totalRecords: number | null | undefined;
  maxResultCount: number | null | undefined;
};

export const getRestrictionReason = ({
  searchRestrictions,
  systemRestrictions,
  numberOfViewableScreeners,
  totalRecords,
  maxResultCount,
}: GetRestrictionReasonParams): string => {
  const limitCode =
    (searchRestrictions?.restrictionCode ||
      systemRestrictions?.restrictionCode) ??
    '';

  if (limitCode === 'PLAN_LIMIT_MAX_VIEW_SAVED_SCREENER') {
    console.log('numberOfViewableScreeners', numberOfViewableScreeners);
    const limitText =
      typeof numberOfViewableScreeners === 'number'
        ? `${numberOfViewableScreeners} `
        : '';
    return `You've exceeded the limit of ${limitText}screeners included in your plan. To view new companies in this screener, upgrade to the Unlimited Plan.`;
  }

  if (
    limitCode === 'RESULT_SET_TOO_LARGE_FOR_IS_NEW' ||
    limitCode === 'RESULT_SET_TOO_LARGE_FOR_ONLY_NEW'
  ) {
    const isValid = totalRecords && maxResultCount;
    if (isValid) {
      return `Your current filters return ${totalRecords.toLocaleString()} companies. To view new companies, adjust your filters so that total results are less than ${maxResultCount}.`;
    }
  }

  return (
    REASON_MAPPING[limitCode] ||
    searchRestrictions?.restrictionReason ||
    systemRestrictions?.restrictionReason
  );
};

export const checkUsingPredefinedUserQuery = (gridViewId?: number) => {
  const isUserPredefinedScreener = !!gridViewId && !Number.isNaN(gridViewId);
  return isUserPredefinedScreener;
};
