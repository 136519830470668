import { useState, useEffect } from 'react';
import track, { useTracking } from 'react-tracking';
import { Textfield } from '@simplywallst/ui-core';
import { FixedContainer, ScrollableContainer } from '../../styled';
import type { QueryKeyword } from '@/constants/industries';
import CategoryNode, { List } from '../CategoryNode';
import SearchFieldPostfix from '@components/SearchFieldPostfix';

interface Props {
  placeholder?: string;
  name?: string;
  onToggle: (id: string, depth: number) => void;
  selected: string[];
  order: string[];
  listing: Record<string, QueryKeyword>[];
}

interface Category {
  id: string;
  label: string;
  ancestors?: string[];
  children?: string[] | readonly string[];
}

const getListing = (
  listing: Record<string, QueryKeyword>[],
  order: string[]
) => {
  const collection: Category[] = [];
  const primary: [string, QueryKeyword][] = order
    .filter((n) => typeof listing[0][n] !== 'undefined')
    .map((n) => [n, listing[0][n]]);
  for (let i = 0; i < primary.length; i++) {
    const [id, { label, children }] = primary[i];
    collection.push({ id, label, children });

    if (children && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        const secondaryId = children[i];
        const { label, children: secondaryChildren } = listing[1][secondaryId];

        collection.push({
          id: secondaryId,
          label,
          ancestors: [id],
          children: secondaryChildren,
        });

        if (secondaryChildren && secondaryChildren.length > 0) {
          for (let i = 0; i < secondaryChildren.length; i++) {
            const tertiaryId = secondaryChildren[i];
            const { label } = listing[2][tertiaryId];
            collection.push({
              id: tertiaryId,
              label,
              ancestors: [id, secondaryId],
            });
          }
        }
      }
    }
  }
  return {
    primaryIdList: primary.map((n) => n[0]),
    collection,
  };
};

const FilterSearch = ({
  onToggle,
  listing,
  selected,
  order,
  placeholder,
  name,
}: Props) => {
  const { trackEvent } = useTracking();

  const [searchQuery, setSearchQuery] = useState('');
  const [industries, setIndustries] = useState<Category[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [primaryIndustryIds, setPrimaryIndustryIds] = useState<string[]>([]);

  useEffect(() => {
    const { collection, primaryIdList } = getListing(listing, order);
    setIndustries(collection);
    setPrimaryIndustryIds(primaryIdList);
  }, [listing]);

  useEffect(() => {
    setCategoryFilter(
      industries
        .filter((n) => {
          const safeLabel = n.label.toLowerCase();
          const safeQuery = searchQuery.toLowerCase();
          return safeLabel.includes(safeQuery);
        })
        .sort((a) => {
          const safeLabelA = a.label.toLowerCase();
          const safeQuery = searchQuery.toLowerCase();
          if (safeLabelA.slice(0, safeQuery.length) === safeQuery) return -1;
          return 0;
        })
        .reduce((a: string[], b) => {
          a.push(b.id);
          if (b.ancestors) {
            a.push(...b.ancestors);
          }
          return a;
        }, [])
    );
  }, [industries, listing, searchQuery]);

  return (
    <>
      <FixedContainer>
        <Textfield
          name={name || 'filter-search'}
          type="search"
          styleType="standardReverse"
          placeholder={placeholder || `Search`}
          value={searchQuery}
          postfix={
            <SearchFieldPostfix
              styleType="light"
              onReset={() => setSearchQuery('')}
              searchQuery={searchQuery}
            />
          }
          onChange={(e) => {
            setSearchQuery(e.currentTarget.value);
          }}
          onFocus={() =>
            trackEvent({
              action: 'focus',
              modifier: 'search',
            })
          }
        />
      </FixedContainer>
      <ScrollableContainer>
        <List data-cy-id={`${name}-list` || 'filter-list'}>
          {primaryIndustryIds
            .filter((n) => categoryFilter.includes(n))
            .map((n) => {
              const category = listing[0][n];
              return (
                <CategoryNode
                  key={`category-0-node-${n}`}
                  label={category.label}
                  onToggle={onToggle}
                  id={n}
                  categoryFilter={categoryFilter}
                  depth={0}
                  selected={selected}
                  listing={listing}
                >
                  {category.children}
                </CategoryNode>
              );
            })}
        </List>
      </ScrollableContainer>
    </>
  );
};

export default track({
  type: 'track',
})(FilterSearch);
