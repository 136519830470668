import styled, { css } from 'styled-components';

export const List = styled('ul')`
  list-style: none;
  > li {
    padding-bottom: ${({ theme }) => theme.x2spacePx}px;
    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.darkerGrayRGBA(0.2)};
    }
  }
`;

interface ToggleProps {
  depth: number;
}

export const Toggle = styled('div')<ToggleProps>`
  ${({ theme, depth }) => css`
    cursor: pointer;
    padding: ${theme.x1spacePx}px;
    padding-left: ${theme.x2spacePx + theme.x4spacePx * depth}px;
    transition: all 0.25s ease-in-out;
    label {
      margin-bottom: 0;
    }
    &:hover {
      background-color: ${theme.color.brand01RGBA(0.1)};
    }
  `}
`;
