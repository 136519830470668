import { useQuery } from '@tanstack/react-query';
import { getIndustries } from '@simplywallst/services';
import { slugify } from '@utilities/slugify';

export interface Industry {
  label: string;
  slug?: string;
  children: string[];
}

export const useIndustryTree = () => {
  const { data, status } = useQuery({
    queryKey: ['INDUSTRY_TREE'],
    queryFn: async (): Promise<Record<string, Industry>[]> => {
      const response = await getIndustries();

      const { data } = response.data;
      const primary: Record<string, Industry> = Object.values(
        data.primary
      ).reduce((a, b) => {
        a[b.id] = {
          label: b.type,
          slug: slugify(b.type),
          children: [],
        };
        return a;
      }, {});
      const secondary: Record<string, Industry> = Object.values(
        data.secondary
      ).reduce((a, b) => {
        a[b.id] = {
          label: b.type,
          slug: slugify(b.type),
          children: [],
        };
        primary[b.parent].children.push(b.id.toString());
        return a;
      }, {});

      const tertiary: Record<string, Industry> = Object.values(
        data.tertiary
      ).reduce((a, b) => {
        a[b.id] = {
          label: b.type,
          slug: slugify(b.type),
          children: [],
        };
        secondary[b.parent]?.children?.push(b.id.toString());
        return a;
      }, {});

      return [primary, secondary, tertiary];
    },
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {
    data,
    status,
  };
};
