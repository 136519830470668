import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { Dropdown } from '@/components/Dropdown';
import { orderByDropdownOptions } from '../../constants';
import { Grid, Button, Heading, Text, Box, media } from '@simplywallst/ui-core';
import TilesSolid from '@simplywallst/ui-core/icons/TilesSolid';
import ListSolid from '@simplywallst/ui-core/icons/ListSolid';
import LineDownOutline from '@simplywallst/ui-core/icons/LineDownOutline';
import LineUpOutline from '@simplywallst/ui-core/icons/LineUpOutline';
import SortingOutline from '@simplywallst/ui-core/icons/SortingOutline';
import { RecentResultsToggle } from '@/pages/RecentResultsToggle';
import { getRecentResultsToggleText } from '@/pages/RecentResultsToggle/utils';
import styled, { useTheme } from 'styled-components';
import StopSignSolid from '@simplywallst/ui-core/icons/StopSignSolid';

const MobileToggle = styled('span')`
  display: none;
  ${media.lgUp`
    display: inline-block;
  `}
`;

const directionOptions = [
  { option: 'desc', label: 'High to Low' },
  { option: 'asc', label: 'Low to High' },
];

type StocksView = 'table' | 'tile';

interface Props {
  isCalculating?: boolean;
  companiesCount: string;
  sortingRule: string;
  directionRule: string;
  stocksView: StocksView;
  returnRecentCompaniesOnly: boolean;
  newCompaniesCount: number;
  onSortingUpdate: (sortBy: string) => void;
  onDirectionUpdate: (direction: string) => void;
  onViewSelect: (view: StocksView) => void;
  onOnlyRecentSearchesUpdate: (value: boolean) => void;
  isQueryRestricted?: boolean | null;
  maxResultCount: number | null;
}

export const SecondaryHeader = ({
  isCalculating,
  companiesCount,
  sortingRule,
  stocksView,
  returnRecentCompaniesOnly,
  newCompaniesCount,
  onViewSelect,
  onSortingUpdate,
  directionRule,
  onDirectionUpdate,
  onOnlyRecentSearchesUpdate,
  isQueryRestricted,
  maxResultCount,
}: Props) => {
  const { trackEvent } = useTracking();
  const theme = useTheme();

  const handleTableView = useCallback(() => {
    onViewSelect('table');
    trackEvent({
      action: 'click',
      subject: 'table-view',
    });
  }, [onViewSelect, trackEvent]);

  const handleTileView = useCallback(() => {
    onViewSelect('tile');
    trackEvent({
      action: 'click',
      subject: 'tile-view',
    });
  }, [onViewSelect, trackEvent]);

  const isFilterAvailable = Boolean(
    maxResultCount && Number(companiesCount) < maxResultCount
  );

  const selectedOrder = orderByDropdownOptions.find(
    (o) => o.option === sortingRule
  );
  const selectedDirection = directionOptions.find(
    (o) => o.option === directionRule
  );

  return (
    <>
      <Grid
        templateRows="1fr auto"
        templateColumns="1fr auto"
        paddingTop="x3"
        paddingBottom="x1"
        paddingLeft="x2"
        paddingRight="x2"
        paddingLeftLg="x0"
        paddingRightLg="x0"
        gap="16px"
      >
        <Grid>
          <div className="flex items-center gap-4">
            <Heading as="h2" typography="baseStrong">
              {isCalculating ? (
                <MobileToggle>Updating results</MobileToggle>
              ) : (
                <>
                  {companiesCount} <MobileToggle>results</MobileToggle>
                </>
              )}
            </Heading>

            <div>
              <RecentResultsToggle
                onChange={onOnlyRecentSearchesUpdate}
                value={returnRecentCompaniesOnly}
                isLoading={isCalculating}
              >
                {isFilterAvailable ? (
                  <div className="flex items-center">
                    <Box
                      width="6px"
                      height="6px"
                      backgroundColor="attention"
                      borderRadius="x2"
                      marginRight="x1"
                    />
                    {getRecentResultsToggleText({
                      newCompaniesCount,
                      isQueryRestricted,
                    })}
                  </div>
                ) : (
                  <div className="flex items-center">
                    <StopSignSolid
                      fill={
                        returnRecentCompaniesOnly
                          ? theme.color.brand01
                          : theme.color.darkGray
                      }
                    />
                    <Text color="textSoft" typography="tiny">
                      0 new{' '}
                      <MobileToggle>
                        • Filter under {maxResultCount} results to activate
                      </MobileToggle>
                    </Text>
                  </div>
                )}
              </RecentResultsToggle>
            </div>
          </div>
        </Grid>
        <Grid>
          <div className="flex items-center justify-between gap-4">
            <div className="hidden sm:flex">
              <Dropdown
                name="sortBy"
                value={sortingRule}
                options={orderByDropdownOptions}
                onSelect={onSortingUpdate}
                buttonRender={({ onBlur, onFocus, onClick, innerRef }) => (
                  <Button
                    styleType="passive"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onClick={onClick}
                    ref={innerRef}
                    alignIcon="left"
                  >
                    <SortingOutline aria-label="Sort results" />
                    <MobileToggle>{selectedOrder?.label}</MobileToggle>
                  </Button>
                )}
              />
              <Dropdown
                name="direction"
                options={directionOptions}
                value={directionRule}
                onSelect={onDirectionUpdate}
                buttonRender={({ onBlur, onFocus, onClick, innerRef }) => (
                  <Button
                    styleType="passive"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onClick={onClick}
                    ref={innerRef}
                    alignIcon="left"
                  >
                    {directionRule === 'asc' && (
                      <LineUpOutline aria-label="Direction results" />
                    )}
                    {directionRule === 'desc' && (
                      <LineDownOutline aria-label="Direction results" />
                    )}
                    <MobileToggle>{selectedDirection?.label}</MobileToggle>
                  </Button>
                )}
              />
            </div>
            <Button
              aria-label="Toggle list view"
              styleType={stocksView === 'tile' ? 'passive' : 'loud'}
              onClick={handleTableView}
            >
              <ListSolid />
            </Button>
            <Button
              aria-label="Toggle tiles view"
              styleType={stocksView === 'table' ? 'passive' : 'loud'}
              onClick={handleTileView}
            >
              <TilesSolid />
            </Button>
          </div>
        </Grid>
      </Grid>
      <div className="block px-4 sm:hidden">
        <div className="flex justify-start gap-4 pb-4">
          <Dropdown
            name="sortBy"
            value={sortingRule}
            options={orderByDropdownOptions}
            onSelect={onSortingUpdate}
            buttonRender={({ onBlur, onFocus, onClick, innerRef }) => (
              <Button
                styleType="passive"
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onClick}
                ref={innerRef}
                alignIcon="left"
              >
                <SortingOutline aria-label="Sort results" />
                {selectedOrder?.label}
              </Button>
            )}
          />
          <Dropdown
            name="direction"
            options={directionOptions}
            value={directionRule}
            onSelect={onDirectionUpdate}
            buttonRender={({ onBlur, onFocus, onClick, innerRef }) => (
              <Button
                styleType="passive"
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onClick}
                ref={innerRef}
                alignIcon="left"
              >
                {directionRule === 'asc' && (
                  <LineUpOutline aria-label="Direction results" />
                )}
                {directionRule === 'desc' && (
                  <LineDownOutline aria-label="Direction results" />
                )}
                {selectedDirection?.label}
              </Button>
            )}
          />
        </div>
      </div>
    </>
  );
};
