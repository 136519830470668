import { postJson } from "../methods";
function createGrid(payload, headers = {}) {
  return postJson({
    url: "api/grid/view",
    headers,
    payload
  });
}
export {
  createGrid as default
};
