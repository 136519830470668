import { withAuth } from '@utilities/queries';
import {
  getGridView,
  updateGrid,
  createGrid,
  deleteGridView,
  getUserViews,
} from '@simplywallst/services';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useUserScreeners } from './useUserScreeners';

type Grid = Awaited<ReturnType<typeof getGridView>>['data']['data'];
type Request = Parameters<typeof createGrid>[0];
type TypeSlug =
  | 'discover-page-v1'
  | 'discover-page-v2'
  | 'narrative-collection';

interface GridPayload {
  id?: string;
  rules: string;
  name: string;
  description?: string;
}

type Options = {
  id?: string;
  onCreate?: (payload: { id: string; slug: string }) => void;
  onDelete?: () => void;
  enabled?: boolean;
};

export type GridView = {
  id: string;
  rules: string;
  name: string;
  slug: string;
  imageUrl: string | null;
  description: string;
  onlyMarketFiltered: boolean | null;
  crawlable: boolean;
  typeSlug: TypeSlug;
  modifyDate: number | null;
};

const USER_GRID_LIST_QUERY = ['USER_GRID_LIST'] as const;

export const getQueryKey = <T>(id: T extends string ? T : never) =>
  [`GRID_VIEW_${id}`] as const;

export const getDefault = (): GridView => ({
  id: '0',
  rules: '',
  slug: '',
  name: '',
  description: '',
  onlyMarketFiltered: true,
  crawlable: false,
  imageUrl: null,
  typeSlug: 'discover-page-v1',
  modifyDate: null,
});

export const normaliseResponse = ({
  id,
  name,
  slug,
  rule_json,
  description,
  only_market_filtered,
  status,
  image_url,
  type_slug,
  modify_date,
}: Grid): GridView => ({
  id: String(id),
  rules: rule_json,
  imageUrl: image_url,
  slug,
  name,
  description,
  onlyMarketFiltered: only_market_filtered,
  crawlable: !status,
  typeSlug: type_slug,
  modifyDate: typeof modify_date === 'number' ? modify_date : null,
});

export const makeQueryFn = (gridId: string) => async (): Promise<GridView> => {
  const response = await getGridView(gridId);
  return normaliseResponse(response.data.data);
};

const create = async (payload: Request) => await withAuth(createGrid, payload);
const mutate = async (payload: Request, id: string) =>
  await withAuth(updateGrid, id, payload);

const mutateQueryFn = async ({ id, rules, name, description }: GridPayload) => {
  const payload: Request = {
    rule_json: JSON.parse(rules),
    name,
    description,
    // Hardcoding this for now as I'm not convinced it should be necessary to supply.
    // Snowflake scores also appear in the rules, soooo...
    value_score: 0,
    dividends_score: 0,
    future_performance_score: 0,
    health_score: 0,
    past_performance_score: 0,
  };

  const response = await (typeof id === 'undefined'
    ? create(payload)
    : mutate(payload, id));

  return normaliseResponse(response.data.data);
};

const deleteQueryFn = async (id: string) => {
  const response = await withAuth(deleteGridView, id);
  return response.data;
};

/**
 * ### Description
 *
 * This hook manages grid views (screener filters). With it you can:
 *
 * - Retrieve existing grid views by supplying an `id`.
 * - Create a new grid view if the current user is authenticated to do so.
 * - Mutate an existing grid view if the current user is authenticated to do so.
 * - Delete an existing grid view if the current user is authenticated to do so.
 *
 * ### Options
 *
 * - `id` of the grid that you want returned
 * - `onCreate` is a callback function that fires when a new grid has been successfully created
 * - `onDelete` is a callback function that fires when an existing grid has been successfully deleted
 */
export const useGridView = ({
  id = '0',
  enabled,
  onCreate,
  onDelete,
}: Options) => {
  const queryClient = useQueryClient();
  const queryKey = getQueryKey(id);
  /**
   *
   * @TODO exhaustive deps
   *
   */
  const {
    data = getDefault(),
    status,
    fetchStatus,
  } = useQuery({
    queryKey,
    queryFn: makeQueryFn(id),
    retry: false,
    enabled: enabled || (id !== '0' && id !== 'current'),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateGrid, status: mutationStatus } = useMutation({
    mutationFn: async (grid: GridPayload) =>
      mutateQueryFn({
        id,
        ...grid,
      }),
    onMutate: async ({
      rules,
      name,
      description,
    }): Promise<{ prevGrid?: GridView }> => {
      const prevGrid = queryClient.getQueryData<GridView>(queryKey);
      queryClient.setQueryData<GridView>(queryKey, (state = getDefault()) =>
        produce(state, (draft) => {
          draft.name = name;
          draft.rules = rules;
          draft.description = description || '';
        })
      );
      return {
        prevGrid,
      };
    },
    onError: (err, payload, context) => {
      const prevGrid = (context as { prevGrid?: GridView }).prevGrid;
      if (typeof prevGrid !== 'undefined') {
        queryClient.setQueryData<GridView>(queryKey, prevGrid);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      queryClient.invalidateQueries({ queryKey: USER_GRID_LIST_QUERY });
      queryClient.invalidateQueries(useUserScreeners.getAllOptions());
    },
  });

  const { mutate: createGrid, status: creationStatus } = useMutation({
    mutationFn: mutateQueryFn,
    onSuccess: (gridView) => {
      const { id, name, rules, description, slug } = gridView;
      queryClient.setQueryData<GridView>(
        getQueryKey(id),
        (state = getDefault()) =>
          produce(state, (draft) => {
            draft.name = name;
            draft.rules = rules;
            draft.description = description;
            draft.modifyDate = Date.now();
          })
      );
      const setDataFunc = (state: GridView[] = []) =>
        produce(state, (draft) => {
          if (draft.findIndex((n) => n.id === id) !== -1) return;
          draft.push({
            ...gridView,
            modifyDate: Date.now(),
          });
        });
      queryClient.setQueryData<GridView[]>(USER_GRID_LIST_QUERY, setDataFunc);
      queryClient.setQueryData<GridView[]>(
        useUserScreeners.getAllOptions().queryKey,
        setDataFunc
      );
      if (typeof onCreate !== 'undefined') {
        onCreate({ id, slug });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: USER_GRID_LIST_QUERY });
      queryClient.invalidateQueries(useUserScreeners.getAllOptions());
    },
  });

  const { mutate: deleteGrid, status: deletionStatus } = useMutation({
    mutationFn: deleteQueryFn,
    onSuccess: (data, id) => {
      const setDataFunc = (state: GridView[] = []) =>
        produce(state, (draft) => {
          const index = draft.findIndex((n) => n.id === id);
          if (index !== -1) draft.splice(index, 1);
        });

      queryClient.setQueryData<GridView[]>(USER_GRID_LIST_QUERY, setDataFunc);

      queryClient.setQueryData<GridView[]>(
        useUserScreeners.getAllOptions().queryKey,
        setDataFunc
      );

      queryClient.removeQueries({ queryKey: getQueryKey(id) });
      if (typeof onDelete !== 'undefined') onDelete();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: USER_GRID_LIST_QUERY });
      queryClient.invalidateQueries(useUserScreeners.getAllOptions());
    },
  });

  return {
    data,
    status,
    fetchStatus,
    mutateGrid,
    mutationStatus,
    createGrid,
    creationStatus,
    deleteGrid,
    deletionStatus,
  };
};

type UseUserGridListParams = {
  enabled: boolean;
};
export const useUserGridList = ({ enabled }: UseUserGridListParams) => {
  const {
    data = [],
    status,
    isFetching,
  } = useQuery({
    queryKey: USER_GRID_LIST_QUERY,
    queryFn: async (): Promise<GridView[]> => {
      const response = await withAuth(getUserViews);
      return response.data.data.map(normaliseResponse);
    },
    enabled,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    status,
    isFetching,
  };
};
