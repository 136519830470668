import { useState } from 'react';
import FilterSearch from '../FilterSearch';
import {
  ORDERED_MARKETS,
  PRIMARY_MARKETS,
  SECONDARY_MARKETS,
} from '@/constants/markets';

import { resolveMarkets, mapMarkets } from '../ModalFilterEditor/util';

interface Props {
  selectedMarkets: string[];
  onChange: (
    updatedCountries: string[],
    updatedExchangeSymbols: string[]
  ) => void;
}

const SelectMarket = ({ onChange, selectedMarkets }: Props) => {
  const [markets, setMarkets] = useState(selectedMarkets);
  return (
    <FilterSearch
      name="market-filter-search"
      placeholder="Search e.g. 'NYSE'"
      order={ORDERED_MARKETS}
      listing={[PRIMARY_MARKETS, SECONDARY_MARKETS]}
      onToggle={(marketId, depth) => {
        const { countries, exchangeSymbols } = mapMarkets(markets);
        const { updatedCountries, updatedExchangeSymbols } = resolveMarkets(
          marketId,
          depth,
          countries,
          exchangeSymbols
        );
        const newSelectedMarkets = [
          ...updatedCountries,
          ...updatedExchangeSymbols,
        ];
        setMarkets(newSelectedMarkets);
        onChange(updatedCountries, updatedExchangeSymbols);
      }}
      selected={markets}
    />
  );
};

export default SelectMarket;
