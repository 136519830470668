import type { ReactNode } from 'react';
import { Box, Text } from '@simplywallst/ui-core';
import SnowflakeConfig from '../SnowflakeConfig';
import { KeywordSearch } from './components/KeywordSearch';

import { MainGrid, SnowflakeGrid, FiltersGrid, KeywordsGrid } from './styled';

interface Props {
  snowflakeScore: number[];
  onSnowflakeChange: (points: number[]) => void;
  keywords?: string;
  onKeywordChange: (keywords: string) => void;
  filters?: ReactNode[];
}
export function Filters({
  snowflakeScore,
  onSnowflakeChange,
  onKeywordChange,
  keywords,
  filters,
}: Props) {
  return (
    <Box
      paddingLeft="x2spacePx"
      paddingRight="x2spacePx"
      paddingBottom="x2spacePx"
      paddingTop="x2spacePx"
      backgroundColor="noir"
      borderRadius="x0"
      borderRadiusLg="x2"
    >
      <MainGrid>
        <SnowflakeGrid>
          <Text typography="xSmallStrong">Snowflake Filter</Text>
          <Box paddingLeftMd="x4spacePx" paddingRightMd="x4spacePx">
            <SnowflakeConfig
              score={snowflakeScore}
              onChange={onSnowflakeChange}
            />
          </Box>
        </SnowflakeGrid>
        <FiltersGrid>{filters?.map((filter) => filter)}</FiltersGrid>
        <KeywordsGrid>
          <KeywordSearch keywords={keywords} onChange={onKeywordChange} />
        </KeywordsGrid>
      </MainGrid>
    </Box>
  );
}
